/* src/components/Ads/Ads.css */
.ads-ad {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-bottom: 2rem;
}
.ads-ad-wrapper {
  background: #efeff4;
  min-width: 300px;
  min-height: 240px;
  display: flex;
  justify-content: center;
}
@media (min-width: 640px) {
  .ads-ad-leader {
    width: 728px;
    min-height: 90px;
  }
}

/* src/components/Analytics/Analytics.css */
.storybook-analytics-form-dates {
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 1rem;
}
.storybook-analytics-video-filter {
  max-width: 600px;
  display: flex;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 1rem;
  align-items: center;
}
.storybook-analytics-video-filter > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(0.5rem * var(--tw-space-x-reverse));
  margin-left: calc(0.5rem * calc(1 - var(--tw-space-x-reverse)));
}
.storybook-analytics-video-filter button {
  margin-bottom: 0.5rem;
  align-items: center;
}
.storybook-analytics-filter-modal {
  max-height: 80vh;
}
.storybook-analytics-export {
  display: flex;
  justify-content: flex-end;
}

/* src/components/Billings/Billings.css */
.storybook-billings-text-block {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}
.storybook-billings-divider {
  border-bottom: 1px solid #f3f4f6;
}
.storybook-billings-history-action {
  grid-column: span 2 / span 2;
  text-align: right;
}
.storybook-billings-history-action .storybook-forms-buttons {
  margin-top: 0px;
}

/* src/components/Buttons/Buttons.css */
.storybook-buttons-button {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  padding-right: 1rem;
  font-weight: 500;
  border-radius: 0.375rem;
  border-style: none;
}
.storybook-buttons-button:hover {
  --tw-bg-opacity: 0.75;
}
.storybook-buttons-button-disabled {
  --tw-bg-opacity: 0.75;
  cursor: not-allowed;
}
.storybook-buttons-button:disabled {
  --tw-bg-opacity: 0.75;
  cursor: not-allowed;
}
.storybook-buttons-button:disabled svg {
  --tw-text-opacity: 1;
  color: rgb(193 192 208 / var(--tw-text-opacity, 1));
}
.storybook-buttons-button-icon {
  display: flex;
  gap: 0.5rem;
}
.storybook-buttons-button.outline {
  background-color: transparent;
  border-width: 2px;
  border-radius: 0.25rem;
  border-style: solid;
}
.storybook-buttons-button.outline:hover {
  opacity: 0.75;
}
.storybook-buttons-button-default {
  padding: 0px;
  font-weight: 400;
}
.storybook-buttons-button-default.outline {
  border-width: 2px;
  --tw-border-opacity: 1;
  border-color: rgb(0 0 0 / var(--tw-border-opacity, 1));
  --tw-text-opacity: 1;
  color: rgb(0 0 0 / var(--tw-text-opacity, 1));
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  padding-right: 1rem;
}
.storybook-buttons-button-primary {
  --tw-bg-opacity: 1;
  background-color: rgb(255 0 95 / var(--tw-bg-opacity, 1));
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity, 1));
}
.storybook-buttons-button-dark {
  --tw-bg-opacity: 1;
  background-color: rgb(16 8 62 / var(--tw-bg-opacity, 1));
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity, 1));
}
.storybook-buttons-button-dark:hover {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity, 1));
}
.storybook-buttons-button-primary.outline {
  --tw-border-opacity: 1;
  border-color: rgb(255 0 95 / var(--tw-border-opacity, 1));
  --tw-text-opacity: 1;
  color: rgb(255 0 95 / var(--tw-text-opacity, 1));
}
.storybook-buttons-button-primary.outline.push-button {
  --tw-border-opacity: 1;
  border-color: rgb(255 255 255 / var(--tw-border-opacity, 1));
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity, 1));
}
.storybook-buttons-button-secondary {
  --tw-bg-opacity: 1;
  background-color: rgb(81 233 219 / var(--tw-bg-opacity, 1));
  --tw-text-opacity: 1;
  color: rgb(16 8 62 / var(--tw-text-opacity, 1));
}
.storybook-buttons-button-secondary.outline {
  --tw-text-opacity: 1;
  color: rgb(81 233 219 / var(--tw-text-opacity, 1));
  --tw-border-opacity: 1;
  border-color: rgb(81 233 219 / var(--tw-border-opacity, 1));
}
.storybook-buttons-button-gray.outline {
  --tw-text-opacity: 1;
  color: rgb(16 8 62 / var(--tw-text-opacity, 1));
  --tw-border-opacity: 1;
  border-color: rgb(121 121 125 / var(--tw-border-opacity, 1));
}
.storybook-buttons-button-light {
  --tw-bg-opacity: 1;
  background-color: rgb(193 192 208 / var(--tw-bg-opacity, 1));
}
.storybook-buttons-button-light.outline {
  --tw-text-opacity: 1;
  color: rgb(193 192 208 / var(--tw-text-opacity, 1));
  --tw-border-opacity: 1;
  border-color: rgb(193 192 208 / var(--tw-border-opacity, 1));
}
.storybook-buttons-button-white {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity, 1));
}
.storybook-buttons-link {
  display: inline;
  align-items: center;
  text-align: center;
}
.storybook-buttons-button-group {
  display: inline-flex;
  gap: 0.5rem;
}
.storybook-buttons-link-disabled {
  cursor: not-allowed;
  --tw-text-opacity: 1;
  color: rgb(193 192 208 / var(--tw-text-opacity, 1));
}
.storybook-buttons-copy {
  display: flex;
  align-items: center;
  border-radius: 0.5rem;
  border-width: 1px;
  overflow: hidden;
}
.storybook-forms-buttons .storybook-icons-approved-form {
  width: 30px;
  margin-right: 5px;
}
.storybook-buttons-copy input {
  flex: 1 1 0%;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 0.75rem;
  padding-right: 0.75rem;
}
.storybook-buttons-copy textarea {
  flex: 1 1 0%;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 0.75rem;
  padding-right: 0.75rem;
}
.storybook-buttons-copy button {
  width: 115px;
  border-left-width: 1px;
}
.storybook-buttons-copy button:disabled {
  --tw-bg-opacity: 0.75;
  cursor: not-allowed;
}
.storybook-buttons-group {
  border-radius: 0.5rem;
  border-width: 1px;
}
.storybook-buttons-group > div {
  border-radius: 0px;
  border-top-width: 0px;
  border-right-width: 0px;
  border-left-width: 0px;
}
.storybook-buttons-group > div:last-child {
  border-style: none;
}
.storybook-buttons-download {
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  border-width: 1px;
  border-radius: 0.5rem;
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  padding-left: 0.75rem;
  padding-right: 1rem;
}
.storybook-buttons-download-content {
  grid-column: span 2 / span 2;
  display: flex;
  align-items: center;
}
.storybook-buttons-download-content svg {
  --tw-text-opacity: 1;
  color: rgb(193 192 208 / var(--tw-text-opacity, 1));
  margin-right: 0.5rem;
}
.storybook-buttons-download-content-button {
  --tw-text-opacity: 1;
  color: rgb(206 204 216 / var(--tw-text-opacity, 1));
}
.storybook-buttons-button.tooltip:hover {
  opacity: 1;
}
.tooltip:hover .storybook-notifications-tooltip {
  display: block;
}
.storybook-button-sized {
  width: 100px;
}

/* src/components/Calender/Calender.css */
.storybook-calender-day {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  text-align: center;
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity, 1));
  width: 100px;
}
.storybook-calendar-dashboard {
  scroll-snap-type: x mandatory;
  height: 90px;
  width: 100%;
  overflow: hidden;
  display: flex;
}
.storybook-calender-day-dashboard {
  width: 100px;
}
.swiper-wrapper .swiper-slide {
  margin-right: 8px;
  width: 100px;
}
.storybook-events-calender-swiper {
  display: flex;
  flex-direction: row-reverse;
}
.storybook-events-calender-swiper .storybook-layouts-swiper-next {
  position: relative;
  margin-left: 1.25rem;
  border-radius: 0px;
  --tw-bg-opacity: 1;
  background-color: rgb(121 121 125 / var(--tw-bg-opacity, 1));
}
.storybook-events-calender-swiper .storybook-layouts-swiper-next:hover {
  opacity: 0.8;
}
.storybook-events-calender-swiper .storybook-layouts-swiper-next {
  height: 51px;
  transform: translateY(0);
  width: 33px;
}
.storybook-events-calender-swiper .storybook-layouts-swiper-prev {
  position: relative;
  margin-right: 1.25rem;
  border-radius: 0px;
  --tw-bg-opacity: 1;
  background-color: rgb(121 121 125 / var(--tw-bg-opacity, 1));
}
.storybook-events-calender-swiper .storybook-layouts-swiper-prev:hover {
  opacity: 0.8;
}
.storybook-events-calender-swiper .storybook-layouts-swiper-prev {
  transform: translateY(0);
  width: 33px;
  height: 48px;
}
.storybook-events-calender-swiper .storybook-layouts-swiper-prev svg {
  width: 50px;
}
.storybook-events-calender-swiper .storybook-layouts-swiper-next svg {
  width: 50px;
}

/* src/components/Cards/Cards.css */
.storybook-cards-row {
  margin-top: 1.25rem;
  margin-bottom: 1.25rem;
  display: grid;
  grid-template-columns: repeat(1, minmax(0, 1fr));
  border-radius: 0.25rem;
  overflow: hidden;
  grid-gap: 1px;
}
@media (min-width: 768px) {
  .storybook-cards-row {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
}
.storybook-cards-card-default {
  position: relative;
  overflow: hidden;
  border-radius: 0.5rem;
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity, 1));
}
.storybook-cards-card-group {
  position: relative;
  overflow: hidden;
  border-radius: 0.5rem;
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity, 1));
}
.storybook-cards-row .storybook-cards-card-group {
  border-radius: 0px;
}
.storybook-cards-card-group {
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
}
.storybook-cards-row .storybook-cards-card-default {
  border-radius: 0px;
}
.storybook-cards-buttons {
  margin-top: -1px;
  display: flex;
  border-top-width: 1px;
  --tw-bg-opacity: 1;
  background-color: rgb(226 232 240 / var(--tw-bg-opacity, 1));
  --tw-border-opacity: 1;
  border-color: rgb(226 232 240 / var(--tw-border-opacity, 1));
  margin: 0 -0.5px;
}
.storybook-cards-button {
  display: flex;
  flex: 1 1 0%;
  margin: 0 0.5px;
}
.storybook-cards-button-default {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity, 1));
}
.storybook-cards-button-secondary {
  --tw-bg-opacity: 1;
  background-color: rgb(81 233 219 / var(--tw-bg-opacity, 1));
}
.storybook-cards-button a,
.storybook-cards-button button {
  position: relative;
  width: 0px;
  display: inline-flex;
  flex: 1 1 0%;
  align-items: center;
  justify-content: center;
  padding-top: 1rem;
  padding-bottom: 1rem;
  font-size: 0.875rem;
  line-height: 1.25rem;
  font-weight: 500;
  --tw-text-opacity: 1;
  color: rgb(72 72 75 / var(--tw-text-opacity, 1));
  border-bottom-right-radius: 0.5rem;
  border-width: 1px;
  border-color: transparent;
}
.storybook-cards-button svg {
  margin-right: 0.75rem;
}
.storybook-cards-card-button:hover {
  --tw-text-opacity: 1;
  color: rgb(121 121 125 / var(--tw-text-opacity, 1));
}
.storybook-cards-content {
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
}
@media (min-width: 640px) {
  .storybook-cards-content {
    padding: 1.5rem;
  }
}

/* src/components/Channels/Channels.css */
.storybook-channels-container {
  padding: 2rem;
}
.storybook-channels-plans {
  position: relative;
  z-index: 50;
  margin-top: -1rem;
}
@media (min-width: 768px) {
  .storybook-channels-plans {
    margin-top: -5rem;
  }
}
@media (min-width: 1024px) {
  .storybook-channels-plans {
    margin-top: -6rem;
  }
}
.storybook-channels-hero {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  --tw-bg-opacity: 1;
  background-color: rgb(16 8 62 / var(--tw-bg-opacity, 1));
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  padding-top: 6rem;
}
@media (min-width: 768px) {
  .storybook-channels-hero {
    padding-top: 9rem;
  }
}
.storybook-channels-hero-club {
  padding-bottom: 2.5rem;
  padding-top: 2.5rem;
}
@media (min-width: 768px) {
  .storybook-channels-hero-club {
    padding-top: 9rem;
    padding-bottom: 8rem;
  }
}
.storybook-channels-hero-competition {
  padding-bottom: 1.5rem;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  --tw-bg-opacity: 1;
  background-color: rgb(16 8 62 / var(--tw-bg-opacity, 1));
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  padding-top: 6rem;
}
@media (min-width: 768px) {
  .storybook-channels-hero-competition {
    padding-top: 9rem;
    padding-bottom: 2.5rem;
  }
}
.storybook-channels-hero-shadow {
  background:
    transparent linear-gradient(
      270deg,
      #0e0b3b00 0%,
      #0e0b3b 100%) 0 0 no-repeat padding-box;
  position: absolute;
  inset: 0px;
  z-index: 10;
}
.storybook-channels-hero-channel img {
  height: 5rem;
  width: 5rem;
  border-radius: 9999px;
  -o-object-fit: cover;
  object-fit: cover;
}
@media (min-width: 640px) {
  .storybook-channels-hero-channel img {
    height: 8rem;
    width: 8rem;
  }
}
.storybook-title-playlist {
  margin-bottom: 0.5rem;
  font-size: 1.125rem;
  line-height: 1.75rem;
  font-weight: 700;
}
.storybook-channels-select {
  background-color: transparent;
}
.storybook-channels-livestreams {
  padding-top: 2rem;
  padding-bottom: 2rem;
}
.storybook-channels-livestreams .storybook-videos-cards {
  grid-template-columns: repeat(2, minmax(0, 1fr));
}
.storybook-channels-videos-menu {
  margin-left: auto;
  margin-right: auto;
  display: flex;
  width: 100%;
  max-width: 32rem;
  flex-direction: column;
  gap: 2rem;
}
.storybook-channels-videos {
  display: grid;
  grid-template-columns: repeat(1, minmax(0, 1fr));
  gap: 1rem;
  padding-top: 2rem;
}
@media (min-width: 640px) {
  .storybook-channels-videos {
    gap: 2rem;
  }
}
@media (min-width: 1024px) {
  .storybook-channels-videos {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
}
@media (min-width: 1280px) {
  .storybook-channels-videos {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }
}
@media (min-width: 1024px) {
  .storybook-channels-videos-cards {
    grid-column: span 2 / span 2;
  }
}
@media (min-width: 1280px) {
  .storybook-channels-videos-cards {
    grid-column: span 3 / span 3;
  }
}
@media (min-width: 640px) {
  .storybook-channels-videos-cards .storybook-videos-cards {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}
@media (min-width: 1280px) {
  .storybook-channels-videos-cards .storybook-videos-cards {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
}
.storybook-channels-videos-wrapper {
  grid-column: span 4 / span 4;
}
@media (min-width: 640px) {
  .storybook-channels-videos-wrapper {
    grid-column: span 3 / span 3;
  }
}
@media (min-width: 768px) {
  .storybook-channels-videos-wrapper {
    grid-column: span 3 / span 3;
  }
}
@media (min-width: 1024px) {
  .storybook-channels-videos-wrapper {
    grid-column: span 3 / span 3;
  }
}
.storybook-channels-show-teams {
  margin-top: 3rem;
  display: grid;
  grid-template-columns: repeat(8, minmax(0, 1fr));
  justify-content: center;
  gap: 0.5rem;
}
@media (min-width: 640px) {
  .storybook-channels-show-teams {
    grid-template-columns: repeat(10, minmax(0, 1fr));
  }
}
@media (min-width: 768px) {
  .storybook-channels-show-teams {
    grid-template-columns: repeat(12, minmax(0, 1fr));
    gap: 1rem;
  }
}
@media (min-width: 1024px) {
  .storybook-channels-show-teams {
    margin-top: 5rem;
    grid-template-columns: repeat(18, minmax(0, 1fr));
  }
}
.storybook-channels-show-teams img {
  border-radius: 9999px;
  -o-object-fit: cover;
  object-fit: cover;
}
.storybook-channels-edit-team-list {
  border-radius: 0.125rem;
  --tw-bg-opacity: 1;
  background-color: rgb(247 250 252 / var(--tw-bg-opacity, 1));
  padding: 1rem;
  max-height: 500px;
  overflow: auto;
}
.storybook-channels-teams-add-wrapper {
  display: flex;
}
.storybook-channels-teams-add {
  margin-bottom: 2rem;
  margin-left: auto;
}
@media (min-width: 768px) {
  .storybook-channels-brand {
    display: grid;
    grid-template-columns: repeat(12, minmax(0, 1fr));
    gap: 2rem;
  }
}
.storybook-channels-form-layout {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity, 1));
  padding: 2rem;
}
.storybook-channels-branding > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(2rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(2rem * var(--tw-space-y-reverse));
}
.storybook-channels-branding-image {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity, 1));
  height: 15rem;
  width: 100%;
  position: relative;
}
.storybook-channels-branding-image-body {
  position: absolute;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 2rem;
  padding-right: 2rem;
}
.storybook-channels-branding-image-icon {
  height: 12rem;
  width: 12rem;
  --tw-bg-opacity: 1;
  background-color: rgb(145 145 150 / var(--tw-bg-opacity, 1));
  border-radius: 6px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
.storybook-channels-branding-image-logo {
  height: 8rem;
  width: 8rem;
  --tw-bg-opacity: 1;
  background-color: rgb(145 145 150 / var(--tw-bg-opacity, 1));
  border-radius: 9999px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
.storybook-channels-branding-image-logo:nth-child(2) {
  margin-left: 2rem;
  border-radius: 6px;
}
.storybook-channels-branding-image-banner {
  height: 8rem;
  width: 100%;
  --tw-bg-opacity: 1;
  background-color: rgb(145 145 150 / var(--tw-bg-opacity, 1));
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
.storybook-channels-branding-image-watermark {
  height: 4rem;
  width: 4rem;
  border-radius: 9999px;
  --tw-bg-opacity: 1;
  background-color: rgb(145 145 150 / var(--tw-bg-opacity, 1));
  position: absolute;
  right: 2rem;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
.storybook-channels-branding-overlay {
  background-color: rgb(16 8 62 / var(--tw-bg-opacity, 1));
  --tw-bg-opacity: 0.5;
  height: 100%;
  width: 100%;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
}
.storybook-channels-branding-overlay:hover {
  opacity: 1;
}
.storybook-channels-logo-image {
  border-radius: 9999px;
}
.storybook-channels-info {
  display: flex;
  flex-direction: row;
}
.storybook-channels-info-text {
  margin-left: 1rem;
  margin-top: 1.25rem;
}
.storybook-channels-info-title {
  margin-bottom: 0.25rem;
  font-weight: 700;
  --tw-text-opacity: 1;
  color: rgb(16 8 62 / var(--tw-text-opacity, 1));
  font-size: 1.25rem;
  line-height: 1.25rem;
}
.storybook-channels-thumbnail-image {
  border-radius: 0.375rem;
}
.storybook-channels-card {
  display: flex;
  height: auto;
  width: 100%;
  flex-direction: column;
}
.storybook-channels-card-title {
  font-size: 16px !important;
  line-height: 24px !important;
  margin-top: 0.5rem;
  text-align: center;
  font-weight: 700;
  --tw-text-opacity: 1;
  color: rgb(16 8 62 / var(--tw-text-opacity, 1));
}

/* src/components/Channels/TabTypes/TabTypes.css */
.storybook-pages-showcase-tab {
  margin-bottom: 1rem;
}
.storybook-pages-showcase-tab-option {
  flex: 1 1 0%;
  padding: 1rem;
  font-size: 0.875rem;
  line-height: 1.25rem;
  font-weight: 500;
  text-transform: uppercase;
  --tw-text-opacity: 1;
  color: rgb(0 0 0 / var(--tw-text-opacity, 1));
}
.storybook-pages-showcase-tab-option .active {
  --tw-text-opacity: 1;
  color: rgb(16 8 62 / var(--tw-text-opacity, 1));
}
.storybook-pages-showcase-layout-type {
  margin-bottom: 2.5rem;
  display: flex;
  font-size: 1.125rem;
  line-height: 1.75rem;
  font-weight: 700;
  --tw-text-opacity: 1;
  color: rgb(16 8 62 / var(--tw-text-opacity, 1));
}
.storybook-pages-showcase-layout-type-block {
  width: 100%;
  cursor: pointer;
  text-align: center;
}
.storybook-pages-showcase-layout-type-block:first-child {
  margin-right: 0.5rem;
}
.storybook-pages-showcase-layout-type-block span {
  position: relative;
  margin-bottom: 1rem;
  display: block;
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity, 1));
  opacity: 0.8;
  height: 100px;
}
.storybook-pages-showcase-layout-type-block span svg {
  position: absolute;
  left: 50%;
  top: 50%;
  width: 50px;
  fill: #0E0B3B;
  transform: translate(-50%, -50%);
}
.storybook-pages-showcase-layout-type-block.active span {
  box-shadow: 0 12px 32px #00000029;
  opacity: 1;
}
.storybook-channels-showcase-form {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity, 1));
  padding: 1rem;
}
.storybook-channels-form-layout-options {
  margin-bottom: 1rem;
  display: flex;
}
.storybook-channels-form-layout-options button {
  margin-left: auto;
}
.storybook-channels-form-layout-thumbnail {
  margin-bottom: 1rem;
}
.storybook-channels-form-layout-thumbnail figure {
  background-image: url(https://www.martijnkardol.nl/wp-content/uploads/2021/07/placeholder-5.png);
  height: 150px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

/* src/components/Comments/Comments.css */
.storybook-comments-list {
  max-height: 240px;
  overflow-y: scroll;
  padding-bottom: 1rem;
}
.storybook-comments-list-item {
  position: relative;
  padding: 10px 0;
  border-bottom: 1px solid #f3f4f6;
}
.storybook-comments-list-item.owned {
  padding-left: 10px;
  border-left: 3px solid #00ffec;
}

/* src/components/Dropdowns/Dropdowns.css */
.storybook-dropdowns-container {
  transform-origin: top right;
  position: absolute;
  margin-top: 0.5rem;
  min-width: 12rem;
  max-height: 18rem;
  overflow: scroll;
  border-radius: 0.375rem;
  --tw-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
  --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color);
  box-shadow:
    var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000),
    var(--tw-shadow);
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity, 1));
  z-index: 20;
}
.storybook-dropdowns-overlay {
  position: fixed;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  background: none;
  z-index: 10;
}
.storybook-dropdowns-container-open {
  transform-origin: top right;
  position: absolute;
  margin-top: 0.5rem;
  min-width: 12rem;
  max-height: 18rem;
  overflow: scroll;
  border-radius: 0.375rem;
  --tw-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
  --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color);
  box-shadow:
    var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000),
    var(--tw-shadow);
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity, 1));
  z-index: 20;
}
.storybook-dropdowns-container-close {
  transform-origin: top right;
  position: absolute;
  margin-top: 0.5rem;
  min-width: 12rem;
  max-height: 18rem;
  overflow: scroll;
  border-radius: 0.375rem;
  --tw-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
  --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color);
  box-shadow:
    var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000),
    var(--tw-shadow);
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity, 1));
  z-index: 20;
  display: none;
}
.storybook-dropdowns-container-left {
  left: 0px;
}
.storybook-dropdowns-container-center {
  margin-left: auto;
  margin-right: auto;
}
.storybook-dropdowns-container-right {
  right: 0px;
}
.storybook-dropdowns-link {
  display: flex;
  align-items: center;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  padding-right: 3rem;
  font-size: 0.875rem;
  line-height: 1.25rem;
  --tw-text-opacity: 1;
  color: rgb(72 72 75 / var(--tw-text-opacity, 1));
}
.storybook-dropdowns-link:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(247 250 252 / var(--tw-bg-opacity, 1));
}
.storybook-dropdowns-link .storybook-menus-dashboard-avatar-image,
.storybook-dropdowns-link-button .storybook-menus-dashboard-avatar-image {
  margin-right: 0.75rem;
}
.storybook-dropdowns-link-button {
  display: flex;
  align-items: center;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  padding-right: 3rem;
  font-size: 0.875rem;
  line-height: 1.25rem;
  --tw-text-opacity: 1;
  color: rgb(72 72 75 / var(--tw-text-opacity, 1));
}
.storybook-dropdowns-link-button:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(247 250 252 / var(--tw-bg-opacity, 1));
}
.storybook-dropdowns-link-button .storybook-menus-dashboard-avatar-image {
  margin-right: 0.75rem;
}
.storybook-dropdowns-link-button {
  width: 100%;
}

/* src/components/Ecaptures/Ecaptures.css */
.storybook-pages-ecaptures-save-wrapper {
  display: grid;
  grid-template-columns: repeat(12, minmax(0, 1fr));
}
.storybook-ecaptures-player-form {
  position: relative;
  height: 100%;
}
.storybook-ecaptures-list-wrapper {
  overflow: scroll;
  max-height: 300px;
}
.storybook-ecaptures-player-form-wrapper {
  position: absolute;
  top: 50%;
  left: 50%;
  padding: 2rem;
  text-align: center;
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity, 1));
  transform: translate(-50%, -50%);
  min-width: 500px;
}
@media screen and (max-width: 768px) {
  .storybook-ecaptures-player-form-wrapper {
    min-width: 100%;
  }
}
.storybook-ecapture-player-submit .storybook-buttons-button {
  display: flex;
  align-items: center;
}
.storybook-ecaptures-player-form-wrapper img {
  display: none;
  overflow: hidden;
  border-radius: 9999px;
  -o-object-fit: cover;
  object-fit: cover;
}
@media (min-width: 640px) {
  .storybook-ecaptures-player-form-wrapper img {
    display: block;
  }
}
.storybook-ecaptures-player-form-wrapper img {
  width: 95px;
  height: 95px;
  background: #00ffec;
}
.storybook-ecaptures-player-form-wrapper input {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  margin-bottom: 0;
}
.storybook-ecaptures-player-form-wrapper .storybook-forms-field {
  max-width: 90%;
  margin: 0 auto 20px;
}
.storybook-ecaptures-player-form-wrapper .storybook-forms-field .storybook-forms-error {
  position: absolute;
  bottom: -20px;
}
.storybook-ecaptures-player-form-wrapper .storybook-forms-buttons {
  margin-top: 0;
}
.storybook-ecaptures-player-form-wrapper .storybook-forms-buttons .storybook-buttons-button {
  border-radius: 0 5px 5px 0;
  background: #1CADEF;
}

/* src/components/Events/Events.css */
.storybook-events-overview-days {
  margin-bottom: 2rem;
  align-items: center;
}
@media (min-width: 640px) {
  .storybook-events-overview-days {
    display: flex;
  }
  .storybook-events-overview-days .storybook-forms-input {
    width: 33.333333%;
  }
}
.storybook-events-days {
  margin-bottom: 2rem;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}
@media (min-width: 640px) {
  .storybook-events-days {
    margin-bottom: 0px;
    width: 66.666667%;
  }
}
.storybook-events-duration {
  max-width: 250px;
}
.storybook-events-days-day {
  position: relative;
  display: flex;
  cursor: pointer;
  flex-direction: column;
  justify-content: center;
  text-align: center;
}
.storybook-events-show {
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: space-between;
  padding: 1rem;
  text-align: left;
}
@media (min-width: 640px) {
  .storybook-events-show {
    padding: 0px;
    padding-left: 1.5rem;
  }
}
.storybook-events-edit {
  width: 100%;
  cursor: pointer;
  padding: 1rem;
  text-align: left;
}
@media (min-width: 640px) {
  .storybook-events-edit {
    padding: 0px;
    padding-left: 1.5rem;
  }
}
.storybook-events-overview-new {
  width: 50px;
  height: 50px;
  margin-left: auto;
  margin-right: auto;
  cursor: pointer;
  border-radius: 9999px;
  --tw-bg-opacity: 1;
  background-color: rgb(255 0 95 / var(--tw-bg-opacity, 1));
  text-align: center;
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity, 1));
}
.storybook-events-create-form {
  margin-bottom: 1rem;
  border-bottom-width: 2px;
  --tw-border-opacity: 1;
  border-color: rgb(247 250 252 / var(--tw-border-opacity, 1));
}
@media (min-width: 640px) {
  .storybook-events-create-form {
    display: flex;
    padding-bottom: 1rem;
  }
}
.storybook-events-create-form .storybook-forms-thumbnail {
  min-width: 200px;
  padding-bottom: 56.25%;
}
@media (min-width: 640px) {
  .storybook-events-create-form .storybook-forms-thumbnail {
    margin-bottom: 0px;
  }
}
.storybook-events-days-today-active {
  position: absolute;
  left: 0px;
  right: 0px;
  height: 0.25rem;
  width: 100%;
  --tw-bg-opacity: 1;
  background-color: rgb(255 0 95 / var(--tw-bg-opacity, 1));
  bottom: -10px;
}
.storybook-events-save-buttons {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.storybook-events-save-buttons .storybook-forms-buttons {
  margin-top: 0px;
}
.storybook-events-calender-site {
  background-color: #100a28;
}
.storybook-events-calender-icon {
  height: 14px;
  margin-top: 1px;
}

/* src/components/Forms/Forms.css */
.storybook-forms-checkbox {
  display: flex;
  align-items: flex-start;
}
.storybook-forms-checkbox-input input {
  height: 1rem;
  width: 1rem;
  --tw-text-opacity: 1;
  color: rgb(16 8 62 / var(--tw-text-opacity, 1));
  border-width: 1px;
  --tw-border-opacity: 1;
  border-color: rgb(226 232 240 / var(--tw-border-opacity, 1));
  border-radius: 0.25rem;
}
.storybook-forms-checkbox-input {
  height: 1.25rem;
  display: flex;
  align-items: center;
}
.storybook-forms-checkbox-label label {
  font-weight: 500;
  --tw-text-opacity: 1;
  color: rgb(121 121 125 / var(--tw-text-opacity, 1));
  cursor: pointer;
}
.storybook-forms-checkbox-label {
  margin-left: 0.5rem;
  font-size: 0.875rem;
  line-height: 1.25rem;
}
.storybook-forms-container {
  --tw-bg-opacity: 1;
  background-color: rgb(226 232 240 / var(--tw-bg-opacity, 1));
  padding: 1rem;
  border-radius: 0.25rem;
  margin-bottom: 1.5rem;
}
.storybook-forms-error {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
  padding: 0.75rem;
  border-radius: 0.25rem;
  --tw-bg-opacity: 1;
  background-color: rgb(254 226 226 / var(--tw-bg-opacity, 1));
  border-width: 2px;
  --tw-border-opacity: 1;
  border-color: rgb(220 38 38 / var(--tw-border-opacity, 1));
}
.storybook-forms-info {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
  padding: 0.75rem;
  border-radius: 0.25rem;
  background-color: rgb(254 226 226 / var(--tw-bg-opacity, 1));
  border-color: rgb(220 38 38 / var(--tw-border-opacity, 1));
  --tw-bg-opacity: 1;
  background-color: rgb(219 234 254 / var(--tw-bg-opacity, 1));
  --tw-text-opacity: 1;
  color: rgb(37 99 235 / var(--tw-text-opacity, 1));
  border-width: 2px;
  --tw-border-opacity: 1;
  border-color: rgb(37 99 235 / var(--tw-border-opacity, 1));
}
.storybook-forms-confirmation {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
  padding: 0.75rem;
  border-radius: 0.25rem;
  font-size: 0.875rem;
  line-height: 1.25rem;
  --tw-bg-opacity: 1;
  background-color: rgb(220 252 231 / var(--tw-bg-opacity, 1));
  --tw-text-opacity: 1;
  color: rgb(22 163 74 / var(--tw-text-opacity, 1));
  border-width: 2px;
  --tw-border-opacity: 1;
  border-color: rgb(22 163 74 / var(--tw-border-opacity, 1));
}
.storybook-forms-group {
  display: flex;
}
.storybook-forms-input {
  display: block;
  width: 100%;
  border-width: 1px;
  border-radius: 0.375rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  --tw-shadow: 0 1px 2px 0 rgb(0 0 0 / 0.05);
  --tw-shadow-colored: 0 1px 2px 0 var(--tw-shadow-color);
  box-shadow:
    var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000),
    var(--tw-shadow);
  border-color: #cbd5e0;
}
.storybook-forms-input:disabled {
  cursor: not-allowed;
}
.storybook-forms-field {
  margin-bottom: 1rem;
}
.storybook-forms-input-symbol .storybook-forms-input {
  padding-left: 2rem;
}
.storybook-forms-input-symbol {
  position: relative;
}
.storybook-forms-input-symbol-euro:after {
  content: "\20ac";
}
.storybook-forms-input-symbol:after {
  position: absolute;
  padding-left: 0.75rem;
  top: 50%;
  transform: translateY(-50%);
}
.storybook-forms-select {
  display: block;
  width: 100%;
  border-width: 1px;
  border-radius: 0.375rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  --tw-shadow: 0 1px 2px 0 rgb(0 0 0 / 0.05);
  --tw-shadow-colored: 0 1px 2px 0 var(--tw-shadow-color);
  box-shadow:
    var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000),
    var(--tw-shadow);
  border-color: #cbd5e0;
}
.storybook-forms-select:disabled {
  cursor: not-allowed;
}
.storybook-forms-input-symbol .storybook-forms-select {
  padding-left: 2rem;
}
.storybook-forms-select {
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
.storybook-forms-status {
  margin-bottom: 1.5rem;
  display: flex;
  justify-content: space-between;
}
.storybook-forms-status-label {
  font-size: 0.875rem;
  line-height: 1.25rem;
}
.storybook-forms-textarea {
  display: block;
  width: 100%;
  border-width: 1px;
  border-radius: 0.375rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  --tw-shadow: 0 1px 2px 0 rgb(0 0 0 / 0.05);
  --tw-shadow-colored: 0 1px 2px 0 var(--tw-shadow-color);
  box-shadow:
    var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000),
    var(--tw-shadow);
  border-color: #cbd5e0;
}
.storybook-forms-textarea:disabled {
  cursor: not-allowed;
}
.storybook-forms-input-symbol .storybook-forms-textarea {
  padding-left: 2rem;
}
.storybook-forms-label {
  font-size: 0.83em;
}
.storybook-forms-label-lg {
  font-size: 1.125rem;
  line-height: 1.75rem;
  font-weight: 700;
  --tw-text-opacity: 1;
  color: rgb(16 8 62 / var(--tw-text-opacity, 1));
}
.storybook-forms-toggle {
  height: 1.25rem;
  width: 2.5rem;
  --tw-bg-opacity: 1;
  background-color: rgb(193 192 208 / var(--tw-bg-opacity, 1));
  display: inline-flex;
  flex-shrink: 0;
  vertical-align: middle;
  position: relative;
  border-width: 2px;
  border-color: transparent;
  border-radius: 9999px;
  cursor: pointer;
  transition-property:
    color,
    background-color,
    border-color,
    text-decoration-color,
    fill,
    stroke;
  transition-duration: 200ms;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  z-index: 10;
}
.storybook-forms-toggle:disabled {
  cursor: not-allowed;
  --tw-bg-opacity: 1;
  background-color: rgb(255 0 95 / var(--tw-bg-opacity, 1));
}
.storybook-forms-toggle-active .storybook-forms-toggle-handle {
  --tw-translate-x: 1.25rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.storybook-forms-toggle-active {
  height: 1.25rem;
  width: 2.5rem;
  --tw-bg-opacity: 1;
  background-color: rgb(193 192 208 / var(--tw-bg-opacity, 1));
  display: inline-flex;
  flex-shrink: 0;
  vertical-align: middle;
  position: relative;
  border-width: 2px;
  border-color: transparent;
  border-radius: 9999px;
  cursor: pointer;
  transition-property:
    color,
    background-color,
    border-color,
    text-decoration-color,
    fill,
    stroke;
  transition-duration: 200ms;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  z-index: 10;
}
.storybook-forms-toggle-active:disabled {
  cursor: not-allowed;
  --tw-bg-opacity: 1;
  background-color: rgb(255 0 95 / var(--tw-bg-opacity, 1));
}
.storybook-forms-toggle-active:focus {
  outline: 2px solid transparent;
  outline-offset: 2px;
}
.storybook-forms-toggle-active {
  --tw-bg-opacity: 1;
  background-color: rgb(81 233 219 / var(--tw-bg-opacity, 1));
}
.storybook-forms-toggle-handle {
  pointer-events: none;
  --tw-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1);
  --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color), 0 1px 2px -1px var(--tw-shadow-color);
  box-shadow:
    var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000),
    var(--tw-shadow);
  display: inline-block;
  height: 1rem;
  width: 1rem;
  border-radius: 9999px;
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity, 1));
  transition-property:
    color,
    background-color,
    border-color,
    text-decoration-color,
    fill,
    stroke,
    opacity,
    box-shadow,
    transform,
    filter,
    -webkit-backdrop-filter;
  transition-property:
    color,
    background-color,
    border-color,
    text-decoration-color,
    fill,
    stroke,
    opacity,
    box-shadow,
    transform,
    filter,
    backdrop-filter;
  transition-property:
    color,
    background-color,
    border-color,
    text-decoration-color,
    fill,
    stroke,
    opacity,
    box-shadow,
    transform,
    filter,
    backdrop-filter,
    -webkit-backdrop-filter;
  transition-duration: 200ms;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.storybook-forms-toggle:focus {
  outline: 2px solid transparent;
  outline-offset: 2px;
}
.storybook-forms-buttons {
  margin-top: 1rem;
}
.storybook-forms-buttons-wide .storybook-buttons-button {
  width: 100%;
}
.storybook-forms-buttons-right {
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
}
.storybook-forms-buttons-center {
  display: flex;
  justify-content: center;
  gap: 1rem;
}
.storybook-forms-title {
  margin-bottom: 2rem;
  text-align: center;
}
.storybook-forms-new {
  padding: 2.5rem;
  border-width: 2px;
  border-style: dashed;
  --tw-border-opacity: 1;
  border-color: rgb(226 232 240 / var(--tw-border-opacity, 1));
  border-radius: 0.5rem;
  text-align: center;
}
.storybook-forms-new-with-button {
  padding: 2.5rem;
  border-width: 2px;
  border-style: dashed;
  --tw-border-opacity: 1;
  border-color: rgb(226 232 240 / var(--tw-border-opacity, 1));
  border-radius: 0.5rem;
  text-align: center;
}
.storybook-forms-new-with-button .storybook-texts-heading {
  margin-bottom: 1.5rem;
}
.storybook-forms-success {
  margin-top: 0.75rem;
  text-align: center;
}
@media (min-width: 640px) {
  .storybook-forms-success {
    margin-top: 1.25rem;
  }
}
.storybook-forms-success-icon {
  margin-left: auto;
  margin-right: auto;
  display: flex;
  height: 3rem;
  width: 3rem;
  align-items: center;
  justify-content: center;
  border-radius: 9999px;
  --tw-bg-opacity: 1;
  background-color: rgb(81 233 219 / var(--tw-bg-opacity, 1));
}
.storybook-forms-success-buttons {
  margin-top: 1.25rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 0.5rem;
}
@media (min-width: 640px) {
  .storybook-forms-success-buttons {
    margin-top: 1.5rem;
    flex-direction: row;
    gap: 0.75rem;
  }
}
.storybook-forms-success-buttons .storybook-buttons-button {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
}
.storybook-forms-colorpicker {
  position: relative;
}
.storybook-forms-colorpicker-swatch {
  border: 1px solid grey;
  display: flex;
  cursor: pointer;
  align-items: center;
  border-radius: 0.125rem;
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity, 1));
  padding: 1rem;
}
.storybook-forms-colorpicker-swatch-hex {
  text-transform: uppercase;
  --tw-text-opacity: 1;
  color: rgb(121 121 125 / var(--tw-text-opacity, 1));
}
.storybook-forms-colorpicker-swatch-color {
  width: 30px;
  height: 30px;
  margin-left: auto;
  border-radius: 9999px;
}
.storybook-forms-colorpicker-popover {
  position: absolute;
  z-index: 2;
}
.storybook-forms-colorpicker-cover {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.storybook-channels-push-notifications-button {
  position: relative;
}
.storybook-channels-push-notifications-button .storybook-buttons-button {
  padding-left: 60px;
}
.storybook-channels-push-notifications-toggle {
  position: absolute;
  top: 12px;
  left: 15px;
  pointer-events: none;
}
.storybook-channels-push-notifications-loader {
  height: 1.5rem;
  width: 1.5rem;
  position: absolute;
  left: 15px;
  pointer-events: none;
  top: 10px;
}
.storybook-forms-editor {
  margin-bottom: 1.5rem;
}

/* src/components/Graphs/Graphs.css */
.storybook-graphs-container {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity, 1));
  margin-bottom: 1.25rem;
  padding: 2rem;
  border-radius: 0.25rem;
}
.storybook-graphs-chart {
  margin-bottom: 1.25rem;
}
.storybook-graphs-card-title {
  font-size: 1rem;
  line-height: 1.5rem;
  font-weight: 400;
  --tw-text-opacity: 1;
  color: rgb(24 24 25 / var(--tw-text-opacity, 1));
}
.storybook-graphs-card-stats {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  margin-top: 0.25rem;
}
@media (min-width: 768px) {
  .storybook-graphs-cardstats {
    display: block;
  }
}
@media (min-width: 1024px) {
  .storybook-graphs-card-stats {
    display: flex;
  }
}
.storybook-graphs-card-stats-body {
  display: flex;
  align-items: baseline;
  font-size: 1.5rem;
  line-height: 2rem;
  font-weight: 600;
  --tw-text-opacity: 1;
  color: rgb(16 8 62 / var(--tw-text-opacity, 1));
}
.storybook-graphs-card-stats-body span {
  margin-left: 0.5rem;
  font-size: 0.875rem;
  line-height: 1.25rem;
  font-weight: 500;
  --tw-text-opacity: 1;
  color: rgb(121 121 125 / var(--tw-text-opacity, 1));
}

/* src/components/Layouts/Layouts.css */
.storybook-layouts-container {
  width: 100%;
  padding-left: 1rem;
  padding-right: 1rem;
  margin-left: auto;
  margin-right: auto;
  max-width: 1280px;
}
@media (min-width: 768px) {
  .storybook-layouts-container-sm {
    max-width: 768px;
  }
}
@media (min-width: 1024px) {
  .storybook-layouts-container-md {
    max-width: 1024px;
  }
}
.storybook-layouts-space {
  padding-bottom: 2rem;
}
.storybook-layouts-centered-overlay {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0px;
  left: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.storybook-layouts-container-with-sidebar {
  display: grid;
  grid-template-columns: repeat(1, minmax(0, 1fr));
  gap: 1.5rem;
}
@media (min-width: 1024px) {
  .storybook-layouts-container-with-sidebar {
    grid-auto-flow: column dense;
    grid-template-columns: repeat(8, minmax(0, 1fr));
  }
}
@media (min-width: 1024px) {
  .storybook-layouts-sidebar {
    grid-column: span 3 / span 3;
    margin-bottom: 1.5rem;
  }
}
@media (min-width: 1024px) {
  .storybook-layouts-main {
    grid-column: span 5 / span 5;
  }
}
.storybook-layouts-main {
  position: relative;
}
.storybook-layouts-swiper-next,
.storybook-layouts-swiper-prev {
  position: absolute;
  top: 50%;
  z-index: 10;
  display: flex;
  height: 50%;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  text-align: center;
  transform: translateY(-50%);
  background: #10083ec9;
  width: 65px;
}
.storybook-layouts-swiper-next svg,
.storybook-layouts-swiper-prev svg {
  stroke: white;
  width: 24px;
}
.swiper-button-disabled {
  display: none;
}
.storybook-layouts-swiper-next svg {
  transform: rotate(-90deg);
}
.storybook-layouts-swiper-prev svg {
  transform: rotate(90deg);
}
.storybook-layouts-swiper-next {
  right: 0px;
  border-top-left-radius: 0.375rem;
  border-bottom-left-radius: 0.375rem;
}
.storybook-layouts-swiper-prev {
  left: 0px;
  border-top-right-radius: 0.375rem;
  border-bottom-right-radius: 0.375rem;
}
.layouts-loader {
  padding-bottom: 2rem;
  margin-top: 1.5rem;
  text-align: center;
}
.storybook-layouts-space .storybook-pages-showcase {
  max-width: none;
}
.storybook-layouts-fullscreen {
  position: absolute;
  inset: 0px;
  z-index: 50;
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity, 1));
  padding: 2.5rem;
  text-align: center;
}
@media (min-width: 1024px) {
  .storybook-layouts-fullscreen {
    padding-left: 6rem;
    padding-right: 6rem;
  }
}
.storybook-layouts-fullscreen-close {
  position: fixed;
  right: 1.75rem;
  top: 0.75rem;
  --tw-text-opacity: 1;
  color: rgb(193 192 208 / var(--tw-text-opacity, 1));
}
.storybook-layouts-fullscreen-close:hover {
  --tw-text-opacity: 1;
  color: rgb(16 8 62 / var(--tw-text-opacity, 1));
}
hr {
  border-color: rgb(121 121 125 / var(--tw-border-opacity, 1));
  --tw-border-opacity: 0.25;
}

/* src/components/Likes/Likes.css */
.storybook-likes-wrapper svg {
  stroke: #ff0062;
  width: 30px;
}
.storybook-likes-wrapper svg.fill-like {
  fill: #ff0062;
}

/* src/components/Lists/Lists.css */
.storybook-lists-list-default {
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}
.storybook-lists-list-grid {
  display: grid;
  gap: 0.5rem;
}
.storybook-lists-header {
  border-bottom-width: 1px;
  --tw-border-opacity: 1;
  border-color: rgb(247 250 252 / var(--tw-border-opacity, 1));
  padding: 1.25rem;
}
.storybook-lists-row {
  padding-top: 1rem;
  padding-bottom: 1rem;
  border-bottom-width: 1px;
}
.storybook-lists-row:last-child {
  border-style: none;
}
@media (min-width: 640px) {
  .storybook-lists-row {
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    gap: 1rem;
  }
}
.storybook-lists-column {
  display: flex;
}
.storybook-lists-column-dt {
  display: block;
  --tw-text-opacity: 1;
  color: rgb(193 192 208 / var(--tw-text-opacity, 1));
}
.storybook-lists-item-row {
  list-style-type: none;
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity, 1));
  border-radius: 0.25rem;
  overflow: hidden;
}
.storybook-lists-item-body {
  display: flex;
  align-items: center;
}
.storybook-lists-item-body:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(247 250 252 / var(--tw-bg-opacity, 1));
}
.storybook-lists-item-enabled {
  border-left-width: 4px;
  --tw-border-opacity: 1;
  border-color: rgb(81 233 219 / var(--tw-border-opacity, 1));
}
.storybook-lists-item-disabled {
  border-left-width: 4px;
  --tw-border-opacity: 1;
  border-color: rgb(239 68 68 / var(--tw-border-opacity, 1));
}
.storybook-lists-buttons {
  display: flex;
  gap: 0.5rem;
  --tw-text-opacity: 1;
  color: rgb(16 8 62 / var(--tw-text-opacity, 1));
  border-left-width: 2px;
  padding: 1rem;
}
.storybook-lists-content {
  min-width: 0px;
}
@media (min-width: 640px) {
  .storybook-lists-content {
    padding: 1rem;
  }
}
.storybook-lists-content {
  display: flex;
  flex: 1 1 0%;
  align-items: center;
}
.storybook-lists-filters {
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 1.5rem;
  display: flex;
  gap: 1.5rem;
  max-width: 640px;
}

/* src/components/Livestreams/Livestreams.css */
.storybook-livestreams-form-status {
  width: 100%;
  margin-bottom: 1rem;
}
.storybook-livestreams-form-chat {
  margin-bottom: 1rem;
}
.storybook-livestreams-form-chat .storybook-cards-content {
  display: flex;
  flex-direction: column;
}
.storybook-livestreams-form-chat .storybook-cards-content .storybook-livestreams-form-chat-wrapper {
  align-items: center;
  display: flex;
  margin-bottom: 15px;
}
.storybook-livestreams-form-chat .storybook-cards-content .storybook-livestreams-form-chat-wrapper div {
  margin-left: auto;
}
.storybook-livestreams-form-chat .storybook-forms-status-label {
  margin-left: 10px;
}
.storybook-livestreams-form-chat-text {
  flex: 0 0 100%;
}
.storybook-livestreams-ingest-information {
  margin-bottom: 1rem;
  display: grid;
  gap: 0.5rem;
}
.storybook-livestreams-row-swiper .storybook-videos-card-live-wrapper {
  margin-bottom: 0px;
}
.storybook-livestreams-row-swiper .storybook-videos-card {
  display: block;
}
.storybook-livestreams-row-swiper .storybook-videos-card-thumbnail {
  width: 100%;
  height: auto;
  padding-bottom: 56.25%;
}
.storybook-livestreams-row-heading svg {
  margin-top: -4px;
  margin-left: 5px;
}
.storybook-livestreams-row-swiper .storybook-video-views {
  display: none;
}
.storybook-livestreams-row-swiper .storybook-layouts-swiper-next {
  transform: none;
  height: 100%;
  top: 0;
}
.storybook-livestreams-row-swiper .storybook-layouts-swiper-prev {
  transform: none;
  height: 100%;
  top: 0;
}
.storybook-livestreams-viewers {
  position: absolute;
  top: 1rem;
  left: 1rem;
  z-index: 10;
  display: flex;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  align-items: center;
  gap: 0.375rem;
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity, 1));
}
.storybook-livestreams-viewers span {
  display: inline-block;
  border-radius: 0.25rem;
  --tw-bg-opacity: 1;
  background-color: rgb(239 68 68 / var(--tw-bg-opacity, 1));
  padding-left: 0.375rem;
  padding-right: 0.375rem;
  padding-top: 1px;
  padding-bottom: 1px;
  text-transform: uppercase;
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity, 1));
}
.storybook-livestreams-viewers-count {
  display: flex;
  justify-content: space-evenly;
  gap: 0.25rem;
  border-radius: 0.25rem;
  background-color: rgb(0 0 0 / var(--tw-bg-opacity, 1));
  --tw-bg-opacity: 0.4;
  padding-left: 0.375rem;
  padding-right: 0.375rem;
  padding-top: 1px;
  padding-bottom: 1px;
  transition-property:
    color,
    background-color,
    border-color,
    text-decoration-color,
    fill,
    stroke;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}
.storybook-livestreams-viewers-count:hover {
  --tw-bg-opacity: 0.6;
}
.storybook-livestreams-viewers-count {
  min-width: 44px;
}

/* src/components/Menus/Menus.css */
.storybook-menus-dashboard-header {
  --tw-bg-opacity: 1;
  background-color: rgb(40 4 123 / var(--tw-bg-opacity, 1));
  margin-bottom: 2rem;
}
.storybook-menus-dashboard-nav {
  --tw-bg-opacity: 1;
  background-color: rgb(40 4 123 / var(--tw-bg-opacity, 1));
  position: sticky;
  top: 0px;
  z-index: 50;
}
.storybook-menus-dashboard-header-top {
  height: 4rem;
  display: flex;
  justify-content: space-between;
}
.storybook-menus-dashboard-header-top-left {
  z-index: 20;
  display: flex;
}
.storybook-menus-dashboard-header-sidebar {
  align-self: center;
  margin-right: 1rem;
}
.storybook-menus-dashboard-header-brand {
  display: flex;
  flex-shrink: 0;
  align-items: center;
}
.storybook-menus-dashboard-logo svg {
  color: white;
  fill: currentColor;
  height: 2rem;
  width: auto;
  display: block;
}
#storybook-menus-dashboard-eyecons-logo-e {
  display: block;
}
#storybook-menus-dashboard-eyecons-logo-full {
  display: none;
}
@media (min-width: 1024px) {
  #storybook-menus-dashboard-eyecons-logo-e {
    display: none;
  }
  #storybook-menus-dashboard-eyecons-logo-full {
    display: block;
  }
}
.storybook-menus-dashboard-header-items {
  display: none;
}
@media (min-width: 640px) {
  .storybook-menus-dashboard-header-items {
    display: flex;
    margin-top: -1px;
    margin-bottom: -1px;
    margin-left: 1.5rem;
  }
}
.storybook-menus-dashboard-item {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity, 1));
  display: inline-flex;
  align-items: center;
  padding-left: 1rem;
  padding-right: 1rem;
  border-bottom-width: 2px;
  border-color: transparent;
}
.storybook-menus-dashboard-item:hover {
  --tw-text-opacity: 1;
  color: rgb(81 233 219 / var(--tw-text-opacity, 1));
  cursor: pointer;
}
.storybook-menus-dashboard-item span {
  font-size: 0.75rem;
  font-weight: 500;
  display: inline-flex;
  --tw-bg-opacity: 1;
  background-color: rgb(255 0 95 / var(--tw-bg-opacity, 1));
  align-content: center;
  justify-content: center;
  border-radius: 9999px;
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity, 1));
  align-self: center;
  margin-left: 0.5rem;
  height: 1.25rem;
  width: 1.25rem;
  line-height: 1.25rem;
}
.storybook-menus-dashboard-item-active {
  --tw-border-opacity: 1;
  border-color: rgb(81 233 219 / var(--tw-border-opacity, 1));
  --tw-text-opacity: 1;
  color: rgb(81 233 219 / var(--tw-text-opacity, 1));
}
.storybook-menus-dashboard-item-active:hover {
  --tw-text-opacity: 1;
}
.storybook-menus-dashboard-item,
.storybook-menus-dashboard-label {
  font-size: 0.875rem;
  line-height: 1.25rem;
  font-weight: 500;
  text-transform: uppercase;
}
.storybook-menus-dashboard-header-top-right {
  z-index: 10;
  display: flex;
  align-items: center;
}
.storybook-menus-dashboard-nav-profile {
  position: relative;
  display: flex;
}
.storybook-menus-dashboard-nav-profile-logged {
  position: absolute;
  z-index: 10;
  border-radius: 9999px;
  --tw-bg-opacity: 1;
  background-color: rgb(81 233 219 / var(--tw-bg-opacity, 1));
  --tw-text-opacity: 1;
  color: rgb(16 8 62 / var(--tw-text-opacity, 1));
  top: -5px;
  right: -5px;
  height: 20px;
  width: 20px;
}
.storybook-menus-dashboard-nav-profile .storybook-dropdowns-container-open {
  margin-top: 2.5rem;
}
.storybook-menus-dashboard-avatar-image {
  display: flex;
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity, 1));
  font-size: 0.875rem;
  line-height: 1.25rem;
  border-radius: 9999px;
  display: inline;
}
.storybook-menus-dashboard-avatar-image-sm {
  height: 1.5rem;
  width: 1.5rem;
}
.storybook-menus-dashboard-avatar-image-md {
  height: 2rem;
  width: 2rem;
}
.storybook-menus-dashboard-avatar-image-lg {
  height: 2.5rem;
  width: 2.5rem;
}
.storybook-menus-dashboard-avatar-image-xl {
  height: 3rem;
  width: 3rem;
}
.storybook-menus-dashboard-nav-mobile-button {
  --tw-bg-opacity: 1;
  background-color: rgb(40 4 123 / var(--tw-bg-opacity, 1));
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  border-radius: 0.375rem;
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity, 1));
}
.storybook-menus-dashboard-label {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity, 1));
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.storybook-menus-dashboard-header-button {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  width: 100%;
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity, 1));
}
.storybook-menus-dashboard-title {
  flex: 1 1 0%;
  text-align: left;
  font-size: 1.875rem;
  line-height: 2.25rem;
  font-weight: 700;
}
@media only screen and (max-width: 640px) {
  .storybook-menus-dashboard-title {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .storybook-menus-dashboard-header-mobile-calltoaction {
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    right: 1rem;
    bottom: 1rem;
    height: 3.5rem;
    width: 3.5rem;
    border-radius: 9999px;
    --tw-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
    --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color);
    box-shadow:
      var(--tw-ring-offset-shadow, 0 0 #0000),
      var(--tw-ring-shadow, 0 0 #0000),
      var(--tw-shadow);
    z-index: 10;
  }
}
.storybook-menus-dashboard-title-editor {
  display: flex;
  --tw-text-opacity: 1;
  color: rgb(81 233 219 / var(--tw-text-opacity, 1));
  font-size: 1rem;
  align-self: center;
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 1.25rem;
  padding-right: 1.25rem;
  margin-left: 1.25rem;
  border-left-width: 1px;
  --tw-border-opacity: 1;
  border-color: rgb(81 233 219 / var(--tw-border-opacity, 1));
  line-height: 1rem;
  font-weight: 600;
}
.storybook-menus-dashboard-header-bottom {
  padding-top: 1rem;
  padding-bottom: 1rem;
}
@media (min-width: 640px) {
  .storybook-menus-dashboard-header-bottom .storybook-layouts-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}
.storybook-menus-dashboard-header-dropdown-icon {
  position: absolute;
  right: 0px;
  margin-right: 0.75rem;
}
.storybook-menus-dashboard-header-dropdown {
  position: relative;
}
.storybook-menus-dashboard-header-dropdown > .storybook-buttons-button {
  padding: 0;
}
.storybook-menus-dashboard-header-dropdown .storybook-dropdowns-container-open {
  width: 100%;
}
@media (min-width: 640px) {
  .storybook-menus-dashboard-header-dropdown .storybook-dropdowns-container-open {
    width: 20rem;
  }
}
.storybook-menus-dashboard-hamburger {
  height: 100%;
  display: flex;
  align-items: center;
}
.storybook-menus-dashboard-hamburger .storybook-buttons-button {
  padding: 0px;
}
.storybook-menus-dashboard-nav {
  border-bottom-width: 1px;
}
.storybook-menus-dashboard-nav-tabs {
  background: inherit;
  z-index: auto;
}
.storybook-menus-dashboard-nav-tabs .storybook-menus-dashboard-item {
  --tw-text-opacity: 1;
  color: rgb(193 192 208 / var(--tw-text-opacity, 1));
  padding-top: 1rem;
  padding-bottom: 1rem;
}
.storybook-menus-dashboard-nav-tabs a:hover {
  --tw-text-opacity: 1;
  color: rgb(16 8 62 / var(--tw-text-opacity, 1));
}
.storybook-menus-dashboard-nav-tabs .storybook-menus-dashboard-item-active {
  --tw-border-opacity: 1;
  border-color: rgb(16 8 62 / var(--tw-border-opacity, 1));
  --tw-text-opacity: 1;
  color: rgb(16 8 62 / var(--tw-text-opacity, 1));
}
.storybook-menus-dashboard-sidebar .storybook-texts-link {
  --tw-text-opacity: 1;
  color: rgb(16 8 62 / var(--tw-text-opacity, 1));
}
.storybook-menus-dashboard-sidebar-items {
  display: grid;
  gap: 0.25rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
  margin-bottom: 1rem;
}
.storybook-menus-dashboard-sidebar-item {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
}
.storybook-menus-dashboard-sidebar-container {
  max-width: 100%;
  display: flex;
  position: absolute;
  top: 0px;
  bottom: 0px;
  z-index: 9999;
}
.storybook-menus-dashboard-sidebar-container-left {
  left: 0px;
  padding-right: 2.5rem;
}
.storybook-menus-dashboard-sidebar-container-right {
  right: 0px;
  padding-left: 2.5rem;
}
.storybook-menus-dashboard-sidebar-container > div {
  height: 100%;
  width: 100vw;
  --tw-shadow: 0 20px 25px -5px rgb(0 0 0 / 0.1), 0 8px 10px -6px rgb(0 0 0 / 0.1);
  --tw-shadow-colored: 0 20px 25px -5px var(--tw-shadow-color), 0 8px 10px -6px var(--tw-shadow-color);
  box-shadow:
    var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000),
    var(--tw-shadow);
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity, 1));
  max-width: 28rem;
  overflow-y: scroll;
}
.storybook-menus-dashboard-sidebar-overlay {
  position: fixed;
  inset: 0px;
  overflow: hidden;
}
.storybook-menus-dashboard-sidebar-overlay-open {
  position: fixed;
  inset: 0px;
  overflow: hidden;
}
.storybook-menus-dashboard-sidebar-overlay-close {
  position: fixed;
  inset: 0px;
  overflow: hidden;
  display: none;
}
.storybook-menus-dashboard-sidebar-overlay-1 {
  position: fixed;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  z-index: 10;
}
.storybook-menus-dashboard-sidebar-header {
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
  padding-left: 1rem;
  padding-right: 1rem;
}
@media (min-width: 640px) {
  .storybook-menus-dashboard-sidebar-header {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
}
.storybook-menus-dashboard-sidebar-title {
  font-size: 1.125rem;
  line-height: 1.75rem;
  font-weight: 500;
  --tw-text-opacity: 1;
  color: rgb(24 24 25 / var(--tw-text-opacity, 1));
}
.storybook-menus-dashboard-sidebar-body {
  margin-top: 1.5rem;
  padding-left: 4rem;
  padding-right: 4rem;
  position: relative;
  flex: 1 1 0%;
}
.storybook-menus-dashboard-buttons {
  display: flex;
}
.storybook-menus-dashboard-buttons a {
  display: flex;
  cursor: pointer;
  align-items: center;
}
.storybook-menus-dashboard-buttons a svg {
  width: 15px;
  height: 15px;
  color: black;
  margin-left: 5px;
}
.storybook-menus-dashboard-buttons {
  margin-left: auto;
}
.storybook-menus-dashboard-buttons a {
  cursor: pointer;
}
.storybook-menus-dashboard-buttons .storybook-buttons-button-default {
  color: #1cadef;
  margin-right: 1rem;
  text-decoration-line: underline;
}
.storybook-videos-form-tabs {
  margin-bottom: 1rem;
  border-bottom-width: 1px;
  border-color: rgb(121 121 125 / var(--tw-border-opacity, 1));
  --tw-border-opacity: 0.25;
}
.storybook-videos-form-tabs-desktop {
  display: none;
}
.storybook-videos-form-tabs-mobile {
  padding-bottom: 1rem;
}
@media (min-width: 640px) {
  .storybook-videos-form-tabs-mobile {
    display: none;
  }
  .storybook-videos-form-tabs-desktop {
    display: block;
  }
}

/* src/components/Menus/Dashboard/Dashboard.css */
.storybook-menus-dashboard-header {
  --tw-bg-opacity: 1;
  background-color: rgb(40 4 123 / var(--tw-bg-opacity, 1));
  margin-bottom: 2rem;
}
.storybook-menus-dashboard-nav {
  --tw-bg-opacity: 1;
  background-color: rgb(40 4 123 / var(--tw-bg-opacity, 1));
  position: sticky;
  top: 0px;
  z-index: 50;
}
.storybook-menus-dashboard-header-top {
  height: 4rem;
  display: flex;
  justify-content: space-between;
}
.storybook-menus-dashboard-header-top-left {
  z-index: 20;
  display: flex;
}
.storybook-menus-dashboard-header-sidebar {
  align-self: center;
  margin-right: 1rem;
}
.storybook-menus-dashboard-header-brand {
  display: flex;
  flex-shrink: 0;
  align-items: center;
}
.storybook-menus-dashboard-logo svg {
  color: white;
  fill: currentColor;
  height: 2rem;
  width: auto;
  display: block;
}
#storybook-menus-dashboard-eyecons-logo-e {
  display: block;
}
#storybook-menus-dashboard-eyecons-logo-full {
  display: none;
}
@media (min-width: 1024px) {
  #storybook-menus-dashboard-eyecons-logo-e {
    display: none;
  }
  #storybook-menus-dashboard-eyecons-logo-full {
    display: block;
  }
}
.storybook-menus-dashboard-header-items {
  display: none;
}
@media (min-width: 640px) {
  .storybook-menus-dashboard-header-items {
    display: flex;
    margin-top: -1px;
    margin-bottom: -1px;
    margin-left: 1.5rem;
  }
}
.storybook-menus-dashboard-item {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity, 1));
  display: inline-flex;
  align-items: center;
  padding-left: 1rem;
  padding-right: 1rem;
  border-bottom-width: 2px;
  border-color: transparent;
}
.storybook-menus-dashboard-item:hover {
  --tw-text-opacity: 1;
  color: rgb(81 233 219 / var(--tw-text-opacity, 1));
  cursor: pointer;
}
.storybook-menus-dashboard-item span {
  font-size: 0.75rem;
  font-weight: 500;
  display: inline-flex;
  --tw-bg-opacity: 1;
  background-color: rgb(255 0 95 / var(--tw-bg-opacity, 1));
  align-content: center;
  justify-content: center;
  border-radius: 9999px;
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity, 1));
  align-self: center;
  margin-left: 0.5rem;
  height: 1.25rem;
  width: 1.25rem;
  line-height: 1.25rem;
}
.storybook-menus-dashboard-item-active {
  --tw-border-opacity: 1;
  border-color: rgb(81 233 219 / var(--tw-border-opacity, 1));
  --tw-text-opacity: 1;
  color: rgb(81 233 219 / var(--tw-text-opacity, 1));
}
.storybook-menus-dashboard-item-active:hover {
  --tw-text-opacity: 1;
}
.storybook-menus-dashboard-item,
.storybook-menus-dashboard-label {
  font-size: 0.875rem;
  line-height: 1.25rem;
  font-weight: 500;
  text-transform: uppercase;
}
.storybook-menus-dashboard-header-top-right {
  z-index: 10;
  display: flex;
  align-items: center;
}
.storybook-menus-dashboard-nav-profile {
  position: relative;
  display: flex;
}
.storybook-menus-dashboard-nav-profile-logged {
  position: absolute;
  z-index: 10;
  border-radius: 9999px;
  --tw-bg-opacity: 1;
  background-color: rgb(81 233 219 / var(--tw-bg-opacity, 1));
  --tw-text-opacity: 1;
  color: rgb(16 8 62 / var(--tw-text-opacity, 1));
  top: -5px;
  right: -5px;
  height: 20px;
  width: 20px;
}
.storybook-menus-dashboard-nav-profile .storybook-dropdowns-container-open {
  margin-top: 2.5rem;
}
.storybook-menus-dashboard-avatar-image-sm {
  height: 1.5rem;
  width: 1.5rem;
}
.storybook-menus-dashboard-avatar-image-md {
  height: 2rem;
  width: 2rem;
}
.storybook-menus-dashboard-avatar-image-lg {
  height: 2.5rem;
  width: 2.5rem;
}
.storybook-menus-dashboard-avatar-image-xl {
  height: 3rem;
  width: 3rem;
}
.storybook-menus-dashboard-nav-mobile-button {
  --tw-bg-opacity: 1;
  background-color: rgb(40 4 123 / var(--tw-bg-opacity, 1));
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  border-radius: 0.375rem;
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity, 1));
}
.storybook-menus-dashboard-label {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity, 1));
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.storybook-menus-dashboard-header-button {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  width: 100%;
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity, 1));
}
.storybook-menus-dashboard-title {
  flex: 1 1 0%;
  text-align: left;
  font-size: 1.875rem;
  line-height: 2.25rem;
  font-weight: 700;
}
@media only screen and (max-width: 640px) {
  .storybook-menus-dashboard-title {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .storybook-menus-dashboard-header-mobile-calltoaction {
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    right: 1rem;
    bottom: 1rem;
    height: 3.5rem;
    width: 3.5rem;
    border-radius: 9999px;
    --tw-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
    --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color);
    box-shadow:
      var(--tw-ring-offset-shadow, 0 0 #0000),
      var(--tw-ring-shadow, 0 0 #0000),
      var(--tw-shadow);
    z-index: 10;
  }
}
.storybook-menus-dashboard-title-editor {
  display: flex;
  --tw-text-opacity: 1;
  color: rgb(81 233 219 / var(--tw-text-opacity, 1));
  font-size: 1rem;
  align-self: center;
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 1.25rem;
  padding-right: 1.25rem;
  margin-left: 1.25rem;
  border-left-width: 1px;
  --tw-border-opacity: 1;
  border-color: rgb(81 233 219 / var(--tw-border-opacity, 1));
  line-height: 1rem;
  font-weight: 600;
}
.storybook-menus-dashboard-header-bottom {
  padding-top: 1rem;
  padding-bottom: 1rem;
}
@media (min-width: 640px) {
  .storybook-menus-dashboard-header-bottom .storybook-layouts-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}
.storybook-menus-dashboard-header-dropdown-icon {
  position: absolute;
  right: 0px;
  margin-right: 0.75rem;
}
.storybook-menus-dashboard-header-dropdown {
  position: relative;
}
.storybook-menus-dashboard-header-dropdown > .storybook-buttons-button {
  padding: 0;
}
.storybook-menus-dashboard-header-dropdown .storybook-dropdowns-container-open {
  width: 100%;
}
@media (min-width: 640px) {
  .storybook-menus-dashboard-header-dropdown .storybook-dropdowns-container-open {
    width: 20rem;
  }
}
.storybook-menus-dashboard-hamburger {
  height: 100%;
  display: flex;
  align-items: center;
}
.storybook-menus-dashboard-hamburger .storybook-buttons-button {
  padding: 0px;
}
.storybook-menus-dashboard-nav {
  border-bottom-width: 1px;
}
.storybook-menus-dashboard-nav-tabs {
  background: inherit;
  z-index: auto;
}
.storybook-menus-dashboard-nav-tabs .storybook-menus-dashboard-item {
  --tw-text-opacity: 1;
  color: rgb(193 192 208 / var(--tw-text-opacity, 1));
  padding-top: 1rem;
  padding-bottom: 1rem;
}
.storybook-menus-dashboard-nav-tabs a:hover {
  --tw-text-opacity: 1;
  color: rgb(16 8 62 / var(--tw-text-opacity, 1));
}
.storybook-menus-dashboard-nav-tabs .storybook-menus-dashboard-item-active {
  --tw-border-opacity: 1;
  border-color: rgb(16 8 62 / var(--tw-border-opacity, 1));
  --tw-text-opacity: 1;
  color: rgb(16 8 62 / var(--tw-text-opacity, 1));
}
.storybook-menus-dashboard-sidebar .storybook-texts-link {
  --tw-text-opacity: 1;
  color: rgb(16 8 62 / var(--tw-text-opacity, 1));
}
.storybook-menus-dashboard-sidebar-items {
  display: grid;
  gap: 0.25rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
  margin-bottom: 1rem;
}
.storybook-menus-dashboard-sidebar-item {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
}
.storybook-menus-dashboard-sidebar-container {
  max-width: 100%;
  display: flex;
  position: absolute;
  top: 0px;
  bottom: 0px;
  z-index: 9999;
}
.storybook-menus-dashboard-sidebar-container-left {
  left: 0px;
  padding-right: 2.5rem;
}
.storybook-menus-dashboard-sidebar-container-right {
  right: 0px;
  padding-left: 2.5rem;
}
.storybook-menus-dashboard-sidebar-container > div {
  height: 100%;
  width: 100vw;
  --tw-shadow: 0 20px 25px -5px rgb(0 0 0 / 0.1), 0 8px 10px -6px rgb(0 0 0 / 0.1);
  --tw-shadow-colored: 0 20px 25px -5px var(--tw-shadow-color), 0 8px 10px -6px var(--tw-shadow-color);
  box-shadow:
    var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000),
    var(--tw-shadow);
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity, 1));
  max-width: 28rem;
  overflow-y: scroll;
}
.storybook-menus-dashboard-sidebar-overlay {
  position: fixed;
  inset: 0px;
  overflow: hidden;
}
.storybook-menus-dashboard-sidebar-overlay-open {
  position: fixed;
  inset: 0px;
  overflow: hidden;
}
.storybook-menus-dashboard-sidebar-overlay-close {
  position: fixed;
  inset: 0px;
  overflow: hidden;
  display: none;
}
.storybook-menus-dashboard-sidebar-overlay-1 {
  position: fixed;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  z-index: 10;
}
.storybook-menus-dashboard-sidebar-header {
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
  padding-left: 1rem;
  padding-right: 1rem;
}
@media (min-width: 640px) {
  .storybook-menus-dashboard-sidebar-header {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
}
.storybook-menus-dashboard-sidebar-title {
  font-size: 1.125rem;
  line-height: 1.75rem;
  font-weight: 500;
  --tw-text-opacity: 1;
  color: rgb(24 24 25 / var(--tw-text-opacity, 1));
}
.storybook-menus-dashboard-sidebar-body {
  margin-top: 1.5rem;
  padding-left: 4rem;
  padding-right: 4rem;
  position: relative;
  flex: 1 1 0%;
}
.storybook-menus-dashboard-buttons {
  display: flex;
}
.storybook-menus-dashboard-buttons a {
  display: flex;
  cursor: pointer;
  align-items: center;
}
.storybook-menus-dashboard-buttons a svg {
  width: 15px;
  height: 15px;
  color: black;
  margin-left: 5px;
}
.storybook-menus-dashboard-buttons {
  margin-left: auto;
}
.storybook-menus-dashboard-buttons a {
  cursor: pointer;
}
.storybook-menus-dashboard-buttons .storybook-buttons-button-default {
  color: #1cadef;
  margin-right: 1rem;
  text-decoration-line: underline;
}
.storybook-videos-form-tabs {
  margin-bottom: 1rem;
  border-bottom-width: 1px;
  border-color: rgb(121 121 125 / var(--tw-border-opacity, 1));
  --tw-border-opacity: 0.25;
}
.storybook-videos-form-tabs-desktop {
  display: none;
}
.storybook-videos-form-tabs-mobile {
  padding-bottom: 1rem;
}
@media (min-width: 640px) {
  .storybook-videos-form-tabs-mobile {
    display: none;
  }
  .storybook-videos-form-tabs-desktop {
    display: block;
  }
}

/* src/components/Modals/Modals.css */
.storybook-modals-model {
  z-index: 50;
  width: 100%;
}
.storybook-modals-container {
  margin: 1rem;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  overflow: hidden;
  border-radius: 0.75rem;
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity, 1));
  --tw-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1);
  --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color), 0 1px 2px -1px var(--tw-shadow-color);
  box-shadow:
    var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000),
    var(--tw-shadow);
}
.storybook-models-onblur {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 50;
}
.storybook-modals-overlay {
  display: none;
  background-color: rgba(0, 0, 0, .33);
  position: fixed;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  z-index: 9999;
}
.storybook-modals-visible {
  display: flex;
  align-items: center;
  justify-content: center;
}
.storybook-modals-body {
  position: relative;
  padding: 1.5rem;
  padding-top: 4rem;
}
.storybook-modals-body .storybook-texts-primary {
  --tw-text-opacity: 1;
  color: rgb(72 72 75 / var(--tw-text-opacity, 1));
}
.storybook-modals-footer {
  display: flex;
  justify-content: flex-end;
  --tw-bg-opacity: 1;
  background-color: rgb(247 250 252 / var(--tw-bg-opacity, 1));
  padding-top: 1rem;
  padding-bottom: 1rem;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}
.storybook-modals-footer button {
  margin-left: 0.5rem;
}
.storybook-modals-notification-overlay {
  max-width: 640px;
  padding: 1rem;
  position: fixed;
  top: 0px;
  right: 0px;
  display: flex;
  flex-direction: column-reverse;
  z-index: 9999;
}
.storybook-modals-notification {
  z-index: 50;
  border-radius: 0.5rem;
  --tw-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
  --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color);
  box-shadow:
    var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000),
    var(--tw-shadow);
  border-width: 1px;
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity, 1));
  padding: 1rem;
  padding-left: 1rem;
  padding-right: 1rem;
  margin-bottom: 1rem;
}
.storybook-modals-notification-icon {
  width: 1.5rem;
  margin-right: 1rem;
}
.storybook-modals-notification-body {
  display: flex;
  align-items: flex-start;
}
.storybook-modals-notification-content {
  flex: 1 1 0%;
  margin-right: 1rem;
}
.storybook-modals-notification-close {
  width: 1.5rem;
}
.storybook-modals-notification-close .storybook-buttons-button-default {
  padding: 0px;
  background-color: transparent;
}

/* src/components/Notifications/Notifications.css */
.storybook-notifications-overlay {
  max-width: 640px;
  min-width: 320px;
  padding: 1rem;
  position: fixed;
  top: 0px;
  right: 0px;
  display: flex;
  flex-direction: column-reverse;
  z-index: 999;
}
.storybook-notifications-notification {
  z-index: 50;
  border-radius: 0.5rem;
  --tw-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
  --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color);
  box-shadow:
    var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000),
    var(--tw-shadow);
  border-width: 1px;
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity, 1));
  padding: 1rem;
  padding-left: 1rem;
  padding-right: 1rem;
  margin-bottom: 1rem;
  width: 100%;
}
.storybook-notifications-icon {
  width: 1.5rem;
  margin-right: 1rem;
}
.storybook-notifications-body {
  display: flex;
  align-items: flex-start;
}
.storybook-notifications-content {
  flex: 1 1 0%;
  margin-right: 1rem;
}
.storybook-notifications-close {
  width: 1.5rem;
}
.storybook-notifications-close:hover {
  color: inherit;
}
.storybook-notifications-close .storybook-buttons-button-default {
  padding: 0px;
  background-color: transparent;
}
.storybook-notifications-warning {
}
.storybook-notifications-centered {
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 400px;
  margin-left: auto;
  margin-right: auto;
}
.storybook-notifications-tooltip {
  position: absolute;
  display: none;
  border-radius: 0.125rem;
  --tw-bg-opacity: 1;
  background-color: rgb(121 121 125 / var(--tw-bg-opacity, 1));
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity, 1));
  --tw-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
  --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color), 0 2px 4px -2px var(--tw-shadow-color);
  box-shadow:
    var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000),
    var(--tw-shadow);
  z-index: 999;
  min-width: 150px;
}
.storybook-notifications-tooltip::after {
  content: "";
  position: absolute;
  width: 15px;
  height: 15px;
  z-index: 999;
  display: block;
  --tw-bg-opacity: 1;
  background-color: rgb(121 121 125 / var(--tw-bg-opacity, 1));
}
.storybook-notifications-tooltip.left {
  top: 50%;
  transform: translateY(-50%);
  left: -100%;
  right: calc(100% + 20px);
}
.storybook-notifications-tooltip.left::after {
  top: 50%;
  transform: translateY(-50%) rotate(-45deg);
  right: -8px;
}
.storybook-notifications-tooltip.right {
  top: 50%;
  transform: translateY(-50%);
  right: -100%;
  left: calc(100% + 20px);
}
.storybook-notifications-tooltip.right::after {
  top: 50%;
  transform: translateY(-50%) rotate(-45deg);
  left: -8px;
}
.storybook-notifications-tooltip.top {
  top: 0;
  left: 50%;
  transform: translate(-50%, calc(-100% - 20px));
}
.storybook-notifications-tooltip.top::after {
  bottom: -8px;
  left: 50%;
  transform: translateX(-50%) rotate(45deg);
}
.storybook-notifications-tooltip.bottom {
  bottom: 0;
  left: 50%;
  transform: translate(-50%, calc(100% + 20px));
}
.storybook-notifications-tooltip.bottom::after {
  top: -8px;
  left: 50%;
  transform: translateX(-50%) rotate(45deg);
}
.storybook-notifications-image {
  overflow: hidden;
  margin-right: 1rem;
  width: 3.5rem;
  border-radius: 9999px;
}
.storybook-notifications-image img {
  width: 100%;
}

/* src/components/Plans/Plans.css */
.storybook-plans-card-heading {
  display: flex;
}
.storybook-plans-card-image {
  --tw-bg-opacity: 1;
  background-color: rgb(16 8 62 / var(--tw-bg-opacity, 1));
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  padding-bottom: 56.25%;
}
.storybook-plans-card-heading .storybook-texts-heading {
  flex: 1 1 0%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.storybook-plans-card-icons {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  gap: 0.5rem;
  margin-left: 0.75rem;
}
.storybook-plans-cards-button-month a {
  --tw-text-opacity: 1;
  color: rgb(16 8 62 / var(--tw-text-opacity, 1));
}
.storybook-plans-cards-button-year a {
  --tw-text-opacity: 1;
  color: rgb(193 192 208 / var(--tw-text-opacity, 1));
}

/* src/components/Players/Players.css */
.storybook-players-player-draft {
  position: relative;
  --tw-bg-opacity: 1;
  background-color: rgb(0 0 0 / var(--tw-bg-opacity, 1));
  padding-bottom: 56.25%;
}
.storybook-players-player-draft div {
  position: absolute;
  inset: 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

/* src/components/Playlists/Playlists.css */
.storybook-playlists-videos {
  display: grid;
  gap: 0.5rem;
}
.storybook-playlists-form-status {
  margin-bottom: 1rem;
  display: flex;
  justify-content: space-between;
}
.storybook-playlists-form-video-row-left {
  min-width: 0px;
  display: flex;
  flex: 1 1 0%;
  align-items: center;
}
.storybook-playlists-form-video-row-right {
  height: 2rem;
  padding-left: 0.5rem;
  display: flex;
  align-items: center;
  --tw-text-opacity: 1;
  color: rgb(16 8 62 / var(--tw-text-opacity, 1));
  border-left-width: 2px;
}
@media (min-width: 640px) {
  .storybook-playlists-form-video-row-right {
    padding-left: 1rem;
  }
}
.storybook-playlists-form-video-row-right button {
}
.storybook-playlists-form-video-image {
  flex-shrink: 0;
}
.storybook-playlists-form-video-image > div {
  width: 6rem;
  flex-shrink: 0;
}
@media (min-width: 640px) {
  .storybook-playlists-form-video-image > div {
    width: 8rem;
  }
}
@media (min-width: 1024px) {
  .storybook-playlists-form-video-image > div {
    width: 10rem;
  }
}
.storybook-playlists-form-video-image img {
  position: absolute;
  height: 100%;
  width: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}
.storybook-playlists-form-video-row-content {
  min-width: 0px;
  flex: 1 1 0%;
  padding-left: 1rem;
  padding-right: 1rem;
  align-items: center;
}
.storybook-playlists-form-video-description {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}

/* src/components/Subscriptions/Subscriptions.css */
@media (min-width: 768px) {
  .storybook-subscriptions-create {
    display: flex;
    gap: 2rem;
  }
}
.storybook-subscriptions-create {
  height: 75vh;
}
.storybook-subscription-info {
  margin-bottom: 2rem;
}
@media (min-width: 768px) {
  .storybook-subscription-info {
    width: 33.333333%;
  }
  .storybook-subscription-form {
    width: 66.666667%;
  }
}
.storybook-subscriptions-anchor {
  --tw-text-opacity: 1;
  color: rgb(96 165 250 / var(--tw-text-opacity, 1));
}
.storybook-subscriptions-buttons {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 0.5rem;
}
.storybook-subscriptions-buttons .storybook-icons-loader-form {
  order: 3;
  grid-column: span 2 / span 2;
  margin-top: 1rem;
  justify-self: center;
}
.storybook-subscriptions-button {
  width: 100%;
  text-align: left;
}
.storybook-subscriptions-button span {
  display: block;
  font-size: 0.75rem;
  line-height: 1rem;
}
.storybook-subscription-thumbnail {
  height: 15rem;
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
@media (min-width: 640px) {
  .storybook-subscription-thumbnail {
    margin-top: 0px;
    margin-bottom: 0px;
  }
}
.storybook-subscription-card {
  background: #10083e;
  width: 100%;
}
@media (min-width: 640px) {
  .storybook-subscription-card {
    display: flex;
    align-items: flex-end;
  }
}
.storybook-subscription-cards {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
@media (min-width: 1280px) {
  .storybook-subscription-cards {
    display: grid;
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
}
.storybook-subscription-card-subscribe {
  z-index: 50;
}
.storybook-subscription-card-subscribe:hover {
  --tw-bg-opacity: 1;
}
@media (min-width: 1280px) {
  .storybook-subscription-cards-multiple {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}
.storybook-subscription-card-image {
  position: absolute;
  inset: 0px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
.storybook-subscription-card-image-before {
  position: relative;
}
@media (min-width: 640px) {
  .storybook-subscription-card-image-before {
    display: none;
  }
}
.storybook-subscription-card-image-before {
  height: 150px;
}
.storybook-subscription-card-image-after {
  display: none;
}
@media (min-width: 640px) {
  .storybook-subscription-card-image-after {
    display: block;
  }
}
.storybook-subscription-card-image div {
  background:
    transparent linear-gradient(
      270deg,
      #0e0b3b00 0%,
      #0e0b3b 100%) 0 0 no-repeat padding-box;
  position: absolute;
  inset: 0px;
  z-index: 10;
}
.storybook-subscription-card h2,
.storybook-subscription-card p {
  width: 100%;
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity, 1));
}
.storybook-subscription-card h2 {
  margin-bottom: 0.5rem;
  font-size: 1.25rem;
  line-height: 1.75rem;
  font-weight: 700;
}
@media (min-width: 768px) {
  .storybook-subscription-card h2 {
    margin-bottom: 1rem;
  }
}
.storybook-subscription-card p {
  margin-bottom: 0.5rem;
  font-size: 0.75rem;
  line-height: 1rem;
}
@media (min-width: 768px) {
  .storybook-subscription-card p {
    margin-bottom: 1rem;
  }
}
.storybook-subscription-card-info {
  width: 100%;
  padding: 1.5rem;
}
@media (min-width: 640px) {
  .storybook-subscription-card-info {
    width: 60%;
  }
}
.storybook-subscription-card-action {
  position: relative;
  height: 100%;
  width: 100%;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  padding-bottom: 1.5rem;
}
@media (min-width: 640px) {
  .storybook-subscription-card-action {
    display: flex;
    width: 40%;
    align-items: flex-end;
    justify-content: flex-end;
    text-align: right;
  }
}
.storybook-subscription-card-action a {
  display: block;
  width: 100%;
}
.storybook-subscription-card-buttons {
  display: flex;
  width: 100%;
  max-width: 20rem;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 0.5rem;
}
@media (min-width: 1024px) {
  .storybook-subscription-card-buttons {
    gap: 1rem;
  }
}
.storybook-subscriptions-price {
  font-size: 24px;
  display: flex;
  align-items: center;
}
.storybook-subscriptions-price span {
  font-size: 12px;
}
.storybook-subscriptions-price-light {
  --tw-text-opacity: 1;
  color: rgb(193 192 208 / var(--tw-text-opacity, 1));
  font-size: 24px;
  display: flex;
  align-items: center;
}
.storybook-subscriptions-price-light span {
  font-size: 12px;
}
.storybook-subscriptions-price-secondary {
  --tw-text-opacity: 1;
  color: rgb(81 233 219 / var(--tw-text-opacity, 1));
  font-size: 24px;
  display: flex;
  align-items: center;
}
.storybook-subscriptions-price-secondary span {
  font-size: 12px;
}
.storybook-subscriptions-hero {
  --tw-bg-opacity: 1;
  background-color: rgb(16 8 62 / var(--tw-bg-opacity, 1));
  background-size: cover;
  background-position: center;
  padding-top: 2.5rem;
  padding-bottom: 13rem;
}
@media (min-width: 768px) {
  .storybook-subscriptions-hero {
    padding-top: 3rem;
  }
}
@media (min-width: 1024px) {
  .storybook-subscriptions-hero {
    padding-bottom: 16rem;
  }
}
.storybook-subscriptions-hero-title {
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 1rem;
  text-align: center;
  font-weight: 700;
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity, 1));
}
@media (min-width: 640px) {
  .storybook-subscriptions-hero-title {
    max-width: 24rem;
  }
}
@media (min-width: 768px) {
  .storybook-subscriptions-hero-title {
    margin-bottom: 1.5rem;
  }
}
@media (min-width: 1024px) {
  .storybook-subscriptions-hero-title {
    margin-bottom: 2.5rem;
    max-width: 32rem;
  }
}
.storybook-subscriptions-hero-description {
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  font-weight: 500;
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity, 1));
}
@media (min-width: 640px) {
  .storybook-subscriptions-hero-description {
    max-width: 24rem;
  }
}
@media (min-width: 1024px) {
  .storybook-subscriptions-hero-description {
    max-width: 28rem;
  }
}
.storybook-subscriptions-plans {
  margin-top: -9rem;
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 1rem;
}
@media (min-width: 640px) {
  .storybook-subscriptions-plans {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}
.storybook-subscriptions-plans {
  margin-bottom: 120px;
}
@media only screen and (max-width: 767px) {
  .storybook-subscriptions-plans {
    margin-bottom: 90px;
  }
}
.storybook-subscriptions-plans .storybook-cards-card-default {
  border-radius: 0px;
}
.storybook-subscriptions-features {
  margin-left: auto;
  margin-right: auto;
  display: grid;
  max-width: 32rem;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  gap: 1rem;
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity, 1));
}
@media (min-width: 1024px) {
  .storybook-subscriptions-features {
    max-width: 48rem;
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }
}
.storybook-subscriptions-features-feature {
  display: flex;
  align-items: center;
}
.storybook-subscriptions-content-block-center {
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 2rem;
}
@media (min-width: 768px) {
  .storybook-subscriptions-content-block-center {
    width: 66.666667%;
    text-align: center;
  }
}
@media (min-width: 1024px) {
  .storybook-subscriptions-content-block-center {
    width: 33.333333%;
  }
}
.storybook-subscriptions-content-block-with-image {
  margin-bottom: 3rem;
  gap: 2rem;
}
@media (min-width: 768px) {
  .storybook-subscriptions-content-block-with-image {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}
@media (min-width: 1024px) {
  .storybook-subscriptions-content-block-with-image {
    margin-bottom: 5rem;
    gap: 3rem;
  }
}
.storybook-subscriptions-content-block-text {
  margin-top: 1rem;
  padding-left: 1rem;
  padding-right: 1rem;
}
@media (min-width: 768px) {
  .storybook-subscriptions-content-block-text {
    margin-top: 0px;
    padding-left: 0px;
    padding-right: 0px;
  }
}
.storybook-subscriptions-content-block-with-image .storybook-subscriptions-content-block-text {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
@media (min-width: 768px) {
  .storybook-subscriptions-content-block-with-image .storybook-subscriptions-content-block-text {
    max-width: 80%;
  }
}
.storybook-subscriptions-content-block h1 {
  padding-bottom: 2rem;
  font-size: 1.875rem;
  line-height: 2.25rem;
  font-weight: 500;
  --tw-text-opacity: 1;
  color: rgb(16 8 62 / var(--tw-text-opacity, 1));
}
.storybook-subscriptions-content-block h2 {
  margin-bottom: 2rem;
  font-size: 1.5rem;
  line-height: 2rem;
  font-weight: 500;
  --tw-text-opacity: 1;
  color: rgb(16 8 62 / var(--tw-text-opacity, 1));
}
.storybook-subscriptions-content-block p {
  margin-bottom: 2rem;
  --tw-text-opacity: 1;
  color: rgb(121 121 125 / var(--tw-text-opacity, 1));
}
.storybook-subscriptions-content-blocks-image-small {
  margin-left: auto;
}
@media (min-width: 768px) {
  .storybook-subscriptions-content-blocks-image-small {
    width: 66.666667%;
  }
}
.storybook-subscriptions-content-block:nth-child(even) .storybook-subscriptions-content-block-text {
  order: 1;
  margin-left: auto;
}
.storybook-subscriptions-content-block:nth-child(even) .storybook-subscriptions-content-block-image {
  order: 2;
}

/* src/components/Texts/Texts.css */
.storybook-texts-badge {
  font-size: 0.75rem;
  font-weight: 500;
  text-transform: uppercase;
  display: inline-flex;
  flex-shrink: 0;
  align-items: baseline;
  --tw-bg-opacity: 1;
  background-color: rgb(226 232 240 / var(--tw-bg-opacity, 1));
  border-radius: 9999px;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  height: 1.25rem;
  line-height: 1.25rem;
}
.storybook-texts-badge-primary {
  --tw-bg-opacity: 1;
  background-color: rgb(255 0 95 / var(--tw-bg-opacity, 1));
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity, 1));
}
.storybook-texts-badge-secondary {
  --tw-bg-opacity: 1;
  background-color: rgb(81 233 219 / var(--tw-bg-opacity, 1));
  --tw-text-opacity: 1;
  color: rgb(16 8 62 / var(--tw-text-opacity, 1));
}
.storybook-texts-badge-idle {
  --tw-text-opacity: 1;
  color: rgb(193 192 208 / var(--tw-text-opacity, 1));
}
.storybook-texts-badge-success {
  --tw-bg-opacity: 1;
  background-color: rgb(187 247 208 / var(--tw-bg-opacity, 1));
  --tw-text-opacity: 1;
  color: rgb(34 197 94 / var(--tw-text-opacity, 1));
}
.storybook-texts-badge-live {
  --tw-bg-opacity: 1;
  background-color: rgb(239 68 68 / var(--tw-bg-opacity, 1));
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity, 1));
}
.storybook-texts-heading {
  font-weight: 500;
  --tw-text-opacity: 1;
  color: rgb(16 8 62 / var(--tw-text-opacity, 1));
}
.storybook-texts-heading-h1 {
  font-family:
    Gramatika,
    ui-sans-serif,
    system-ui,
    sans-serif,
    "Apple Color Emoji",
    "Segoe UI Emoji",
    "Segoe UI Symbol",
    "Noto Color Emoji";
  font-size: 61px;
  line-height: 64px;
  font-weight: 900;
}
.storybook-texts-heading-h2 {
  font-family:
    Gramatika,
    ui-sans-serif,
    system-ui,
    sans-serif,
    "Apple Color Emoji",
    "Segoe UI Emoji",
    "Segoe UI Symbol",
    "Noto Color Emoji";
  font-size: 48.8px;
  line-height: 56px;
  font-weight: 900;
}
.storybook-texts-heading-h3 {
  font-family:
    Gramatika,
    ui-sans-serif,
    system-ui,
    sans-serif,
    "Apple Color Emoji",
    "Segoe UI Emoji",
    "Segoe UI Symbol",
    "Noto Color Emoji";
  font-size: 1.25rem;
  line-height: 1.75rem;
}
@media (min-width: 1024px) {
  .storybook-texts-heading-h3 {
    font-size: 2.25rem;
    line-height: 2.5rem;
  }
}
.storybook-texts-heading-h3 {
  line-height: 48px;
  font-weight: 700;
}
.storybook-texts-heading-h4 {
  font-family:
    Gramatika,
    ui-sans-serif,
    system-ui,
    sans-serif,
    "Apple Color Emoji",
    "Segoe UI Emoji",
    "Segoe UI Symbol",
    "Noto Color Emoji";
  font-size: 1.5rem;
  line-height: 2rem;
}
@media (min-width: 1024px) {
  .storybook-texts-heading-h4 {
    font-size: 1.875rem;
    line-height: 2.25rem;
  }
}
.storybook-texts-heading-h4 {
  line-height: 133%;
  font-weight: 700;
}
.storybook-texts-heading-h5 {
  font-family:
    Gramatika,
    ui-sans-serif,
    system-ui,
    sans-serif,
    "Apple Color Emoji",
    "Segoe UI Emoji",
    "Segoe UI Symbol",
    "Noto Color Emoji";
  font-size: 25px;
  line-height: 32px;
  font-weight: 700;
}
.storybook-texts-heading-h6 {
  font-family:
    Gramatika,
    ui-sans-serif,
    system-ui,
    sans-serif,
    "Apple Color Emoji",
    "Segoe UI Emoji",
    "Segoe UI Symbol",
    "Noto Color Emoji";
  font-size: 20px;
  line-height: 32px;
  font-weight: 700;
}
.storybook-texts-link {
  --tw-text-opacity: 1;
  color: rgb(28 173 239 / var(--tw-text-opacity, 1));
}
.storybook-texts-link-disabled {
  cursor: not-allowed;
  --tw-text-opacity: 1;
  color: rgb(193 192 208 / var(--tw-text-opacity, 1));
}
.storybook-texts-primary {
  --tw-text-opacity: 1;
  color: rgb(16 8 62 / var(--tw-text-opacity, 1));
}
.storybook-texts-secondary {
  font-size: 0.875rem;
  line-height: 1.25rem;
  --tw-text-opacity: 1;
  color: rgb(193 192 208 / var(--tw-text-opacity, 1));
}
.storybook-texts-danger {
  font-size: 0.875rem;
  line-height: 1.25rem;
  --tw-text-opacity: 1;
  color: rgb(220 38 38 / var(--tw-text-opacity, 1));
}
.storybook-texts-small {
  font-size: 80%;
  line-height: 1.375;
  display: block;
}
.storybook-texts-span {
  margin-right: 0.5rem;
  --tw-text-opacity: 1;
  color: rgb(193 192 208 / var(--tw-text-opacity, 1));
}
.storybook-texts-label {
  font-size: 0.875rem;
  line-height: 1.25rem;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 0.05em;
  --tw-text-opacity: 1;
  color: rgb(193 192 208 / var(--tw-text-opacity, 1));
  line-height: 24px;
}
.storybook-texts-title {
  font-size: 2.25rem;
  line-height: 2.5rem;
}
.storybook-texts-light {
  font-size: 1rem;
  line-height: 1.5rem;
  --tw-text-opacity: 1;
  color: rgb(193 192 208 / var(--tw-text-opacity, 1));
}

/* src/components/Timelines/Timelines.css */
.storybook-timelines-card {
  position: absolute;
  margin-right: 1rem;
  display: inline-block;
  height: 100%;
  width: 6rem;
  cursor: pointer;
  border-radius: 0.25rem;
  border-width: 1px;
  --tw-border-opacity: 1;
  border-color: rgb(247 250 252 / var(--tw-border-opacity, 1));
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity, 1));
  padding: 0.5rem;
  z-index: 998;
}
.storybook-timelines-card small {
  text-overflow: ellipsis;
  overflow: hidden;
  height: 1.2em;
  white-space: nowrap;
  width: 75px;
  display: block;
}
.storybook-timelines-video-card {
  position: absolute;
  top: 1rem;
  right: 1rem;
  border-radius: 0.25rem;
  border-width: 1px;
  --tw-border-opacity: 1;
  border-color: rgb(247 250 252 / var(--tw-border-opacity, 1));
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity, 1));
  padding: 1rem;
  z-index: 20;
  max-width: 40vw;
  min-width: 200px;
}
@media (min-width: 1024px) {
  .storybook-timelines-video-card {
    max-width: 350px;
    min-width: 350px;
  }
}
.storybook-timelines-video-card a {
  display: block;
  margin-top: 10px;
}
.storybook-timelines-video-card h2 {
  font-size: 20px;
  margin-bottom: 10px;
}
.storybook-timelines-video-card small {
  margin-bottom: 0.5rem;
}
.storybook-timelines-video-card.hide {
  display: none;
}
.storybook-timelines-video-card.show {
  display: block;
}
.storybook-timelines-card:hover {
  --tw-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1);
  --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color), 0 1px 2px -1px var(--tw-shadow-color);
  box-shadow:
    var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000),
    var(--tw-shadow);
}
.storybook-timelines-editor {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
  position: relative;
}
.storybook-timelines-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
@media (min-width: 640px) {
  .storybook-timelines-header {
    padding-left: 2.5rem;
  }
}
.storybook-timelines-timeline-container {
  display: flex;
  align-items: center;
}
.storybook-timelines-menu {
  display: flex;
  width: 100%;
}
.storybook-timelines-timeline-body {
  display: flex;
  width: 100%;
  border-bottom-width: 1px;
  border-right-width: 1px;
  --tw-border-opacity: 1;
  border-color: rgb(247 250 252 / var(--tw-border-opacity, 1));
}
.storybook-timeline-container {
  border-radius: 0;
}
.storybook-timelines-timeline-body div {
  flex: 1 1 0%;
  border-left-width: 1px;
  --tw-border-opacity: 1;
  border-color: rgb(145 145 150 / var(--tw-border-opacity, 1));
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  padding-bottom: 0.5rem;
  font-size: 0.75rem;
  line-height: 1rem;
}
.storybook-timelines-timeline-body div.last {
  flex: 0;
  border-left: 0;
  width: 0;
  position: relative;
  left: -60px;
}
.storybook-timelines-timeline-body div.last.small {
  left: -30px;
}
.storybook-timelines-timeline-body div:last-child {
  text-align: right;
}
.storybook-timelines-timeline-body div:nth-child(even) {
  display: none;
}
@media (min-width: 1024px) {
  .storybook-timelines-timeline-body div:nth-child(even) {
    display: block;
  }
}
.storybook-timelines-timeline-body div.zero {
  text-align: left;
}
.storybook-timelines-cards-track {
  min-height: 45px;
  position: relative;
}
.storybook-timelines-track {
  flex: 1 1 0%;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  overflow: hidden;
  border-bottom-width: 1px;
  --tw-border-opacity: 1;
  border-color: rgb(247 250 252 / var(--tw-border-opacity, 1));
}
.storybook-timelines-track:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(247 250 252 / var(--tw-bg-opacity, 1));
}
.storybook-timelines-track-container {
  display: flex;
  align-items: center;
}
.storybook-timelines-track-content {
  min-height: 3rem;
  position: relative;
}
.storybook-timelines-track-legend {
  flex: none;
  width: 2.5rem;
}
.storybook-timelines-video-track {
  padding: 0.25rem;
  background-color: rgb(81 233 219 / var(--tw-bg-opacity, 1));
  --tw-bg-opacity: 0.25;
  border-radius: 0.25rem;
  border-width: 1px;
  --tw-border-opacity: 1;
  border-color: rgb(81 233 219 / var(--tw-border-opacity, 1));
  border-left-width: 1rem;
  border-right-width: 1rem;
}
.storybook-timelines-video-track-wrapper {
  background-color: rgb(81 233 219 / var(--tw-bg-opacity, 1));
  --tw-bg-opacity: 0.25;
}
.storybook-timelines-video-track-container {
  border-radius: 0.25rem;
  background-color: rgb(81 233 219 / var(--tw-bg-opacity, 1));
  --tw-bg-opacity: 0.25;
}
.storybook-timelines-chapters-track {
  position: relative;
  display: block;
  height: 100%;
  min-height: 3em;
}
.storybook-timelines-chapter-info {
  position: absolute;
  z-index: 10;
  display: none;
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity, 1));
  font-size: 0.875rem;
  line-height: 1.25rem;
  --tw-shadow: 0 1px 2px 0 rgb(0 0 0 / 0.05);
  --tw-shadow-colored: 0 1px 2px 0 var(--tw-shadow-color);
  box-shadow:
    var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000),
    var(--tw-shadow);
  left: 100%;
  top: 0;
  padding: 5px 10px;
}
.storybook-timelines-chapter:hover .storybook-timelines-chapter-info {
  display: block;
}
.storybook-timelines-editor-pointer {
  position: absolute;
  top: 0px;
  height: 100%;
  width: 1px;
  --tw-bg-opacity: 1;
  background-color: rgb(255 0 95 / var(--tw-bg-opacity, 1));
  z-index: 998;
  margin-left: 40px;
}
.storybook-timelines-editor-pointer-plus-chapter,
.storybook-timelines-editor-pointer-plus-card {
  position: absolute;
  top: 141px;
  left: 0;
  display: none;
  cursor: pointer;
}
.storybook-timelines-editor-pointer-plus-chapter::before,
.storybook-timelines-editor-pointer-plus-card::before {
  content: "";
  height: 25px;
  width: 25px;
  border-radius: 50%;
  text-align: center;
  position: absolute;
  left: 50%;
  top: 54%;
  z-index: -1;
  transform: translate(-50%, -50%);
  --tw-bg-opacity: 1;
  background-color: rgb(255 0 95 / var(--tw-bg-opacity, 1));
  cursor: pointer;
}
.storybook-timelines-editor-pointer-plus-chapter::after,
.storybook-timelines-editor-pointer-plus-card::after {
  font-size: 24px;
  font-weight: bold;
  color: white;
  content: "+";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -55%);
}
.storybook-timelines-editor-pointer.active .storybook-timelines-editor-pointer-plus-chapter {
  display: block;
}
.storybook-timelines-editor-pointer.active .storybook-timelines-editor-pointer-plus-card {
  display: block;
}
.storybook-timelines-editor-pointer-plus-chapter {
  top: 206px;
}
.storybook-timelines-cardvideolayer {
  position: absolute;
  left: 1rem;
  right: 1rem;
  top: 1rem;
  z-index: 10;
}
.storybook-timelines-cardvideolayer .storybook-notifications-body {
  flex-direction: column;
}
.storybook-timelines-chapter {
  position: absolute;
  z-index: 20;
  display: inline-block;
  height: 100%;
  border-left-width: 2px;
  --tw-border-opacity: 1;
  border-color: rgb(255 0 95 / var(--tw-border-opacity, 1));
  padding-right: 0.5rem;
  cursor: pointer;
}
.storybook-timelines-chapter .text-primary {
  position: relative;
  top: -7px;
}
.load-video {
  position: relative;
}
.storybook-timelines-stop-edit {
  height: 100vh;
  z-index: 999;
  position: fixed;
  inset: 0px;
}
.storybook-timelines-stop-edit .storybook-icons-loader {
  position: relative;
  top: 3rem;
  z-index: 50;
}
.storybook-timelines-stop-edit::before {
  content: "";
  pointer-events: none;
  opacity: 0.8;
  z-index: 10;
  position: absolute;
  inset: 0px;
  --tw-bg-opacity: 1;
  background-color: rgb(0 0 0 / var(--tw-bg-opacity, 1));
}
.storybook-timelines-stop-edit-wrapper {
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 10;
  text-align: center;
  position: absolute;
}
.storybook-timelines-stop-edit p {
  margin-bottom: 1rem;
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity, 1));
}
.storybook-chapters-form-remove {
  margin-right: 1rem;
  cursor: pointer;
  text-decoration-line: underline;
}

/* src/components/Users/Users.css */
.storybook-users-notifications .storybook-modals-body {
  height: 100%;
}
.storybook-users-notifications-modal .storybook-modals-body {
  border-radius: 0px;
  padding: 0px;
  padding-left: 1rem;
  padding-right: 1rem;
}
.storybook-users-notifications-body {
  overflow: scroll;
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity, 1));
  padding-left: 2rem;
  padding-right: 2rem;
  padding-bottom: 2rem;
  padding-top: 1rem;
}
.storybook-users-notifications-dialog {
  position: fixed;
  inset: 0px;
  overflow: hidden;
  z-index: 9999;
}
.storybook-users-notifications-badge {
  position: absolute;
  display: flex;
  height: 1.25rem;
  width: 1.25rem;
  align-items: center;
  border-radius: 9999px;
  --tw-bg-opacity: 1;
  background-color: rgb(239 68 68 / var(--tw-bg-opacity, 1));
  text-align: center;
  font-weight: 700;
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity, 1));
  top: -5px;
  right: -5px;
  font-size: 14px;
}
.storybook-users-notifications-nothing {
  position: absolute;
  left: 50%;
  top: 50%;
  text-align: center;
  --tw-text-opacity: 1;
  color: rgb(145 145 150 / var(--tw-text-opacity, 1));
  transform: translate(-50%, -50%);
}

/* src/components/Videos/Videos.css */
.storybook-videos-form-container {
  display: grid;
  grid-template-columns: repeat(1, minmax(0, 1fr));
  gap: 1.5rem;
}
@media (min-width: 1024px) {
  .storybook-videos-form-container {
    grid-auto-flow: column dense;
    grid-template-columns: repeat(8, minmax(0, 1fr));
  }
}
@media (min-width: 1024px) {
  .storybook-videos-form-main {
    grid-column: span 5 / span 5;
    grid-column-start: 1;
  }
}
.stoybook-videos-form-playlist {
  margin-bottom: 1rem;
}
.storybook-videos-form-playlist-list {
  margin-top: 1rem;
  overflow-y: scroll;
  max-height: 100px;
}
.storybook-videos-form-publish {
  margin-bottom: 1rem;
}
.storybook-videos-form-publish-title {
  margin-bottom: 1rem;
}
@media (min-width: 1024px) {
  .storybook-videos-form-sidebar {
    grid-column: span 3 / span 3;
    grid-column-start: 6;
    margin-bottom: 1.5rem;
  }
}
.storybook-videos-form-status {
  margin-bottom: 1rem;
}
.storybook-videos-form-status-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1rem;
}
.storybook-videos-form-tabs {
  margin-bottom: 1rem;
  border-bottom-width: 1px;
  border-color: rgb(121 121 125 / var(--tw-border-opacity, 1));
  --tw-border-opacity: 0.25;
}
.storybook-videos-form-tabs-desktop {
  display: none;
}
.storybook-videos-form-tabs-mobile {
  padding-bottom: 1rem;
}
@media (min-width: 640px) {
  .storybook-videos-form-tabs-mobile {
    display: none;
  }
  .storybook-videos-form-tabs-desktop {
    display: block;
  }
}
.storybook-forms-thumbnail {
  --tw-bg-opacity: 1;
  background-color: rgb(16 8 62 / var(--tw-bg-opacity, 1));
  padding-bottom: 56.25%;
  position: relative;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}
.storybook-forms-thumbnail-overlay {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0px;
  left: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgb(16 8 62 / var(--tw-bg-opacity, 1));
  --tw-bg-opacity: 0.75;
  text-align: center;
}
.storybook-forms-thumbnail-icon {
  height: 2.5rem;
  width: 2.5rem;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity, 1));
  margin-bottom: 1rem;
  border-radius: 9999px;
}
.storybook-forms-thumbnail-icon svg {
  --tw-text-opacity: 1;
  color: rgb(16 8 62 / var(--tw-text-opacity, 1));
}
.storybook-forms-thumbnail-text {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity, 1));
}
.storybook-forms-thumbnail-upload {
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
}
.storybook-videos-form-upload {
  --tw-bg-opacity: 1;
  background-color: rgb(16 8 62 / var(--tw-bg-opacity, 1));
  padding-bottom: 56.25%;
  position: relative;
}
.storybook-videos-form-upload-clear {
  background-color: transparent;
  border-radius: 0.5rem;
  border-width: 2px;
  border-style: dashed;
  --tw-border-opacity: 1;
  border-color: rgb(121 121 125 / var(--tw-border-opacity, 1));
}
.storybook-videos-form-upload > div {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0px;
  left: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  --tw-bg-opacity: 0.75;
}
.storybook-videos-form-upload > div > div {
  width: 75%;
  text-align: center;
}
.storybook-videos-form-upload-dropbox {
  margin-bottom: 1.5rem;
  display: inline-flex;
  height: 5rem;
  width: 5rem;
  align-items: center;
  justify-content: center;
  border-radius: 9999px;
}
.storybook-videos-form-upload-clear .storybook-videos-form-upload-dropbox {
  --tw-bg-opacity: 1;
  background-color: rgb(121 121 125 / var(--tw-bg-opacity, 1));
}
.storybook-videos-form-upload-uploader {
  width: 100%;
  position: relative;
}
.storybook-videos-form-upload-loadbar {
  height: 0.5rem;
  display: flex;
  overflow: hidden;
  border-radius: 9999px;
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity, 1));
}
.storybook-videos-form-upload-loadbar > div {
  --tw-bg-opacity: 1;
  background-color: rgb(255 0 95 / var(--tw-bg-opacity, 1));
}
.storybook-videos-share-default {
  display: flex;
  justify-content: center;
  margin-bottom: 1.5rem;
}
.storybook-videos-share-player {
  display: flex;
  flex-direction: column;
}
.storybook-videos-share-icon {
  margin-left: 0.5rem;
  margin-right: 0.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.storybook-videos-share-icon-circle {
  margin-bottom: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 9999px;
  padding: 0.75rem;
}
.storybook-videos-list-item-content {
  min-width: 0px;
  flex: 1 1 0%;
  padding-left: 1rem;
  padding-right: 1rem;
}
@media (min-width: 1024px) {
  .storybook-videos-list-item-content {
    padding-left: 1rem;
    padding-right: 1rem;
  }
}
.storybook-videos-list-item-content {
  align-items: center;
}
@media (min-width: 768px) {
  .storybook-videos-list-item-content {
    gap: 1rem;
  }
}
@media (min-width: 1024px) {
  .storybook-videos-list-item-content {
    gap: 1.5rem;
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}
@media (min-width: 768px) {
  .storybook-videos-list-item-content-simple {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
}
.storybook-videos-list-item-stats-container {
  display: none;
  gap: 1.5rem;
}
@media (min-width: 1024px) {
  .storybook-videos-list-item-stats-container {
    display: flex;
  }
}
.storybook-videos-list-item-statistic {
  text-align: right;
}
.storybook-videos-list-item-statistic .storybook-texts-primary {
  margin-bottom: 0.25rem;
}
.storybook-videos-list-item-stat {
  white-space: nowrap;
  font-size: 0.875rem;
  line-height: 1.25rem;
  --tw-text-opacity: 1;
  color: rgb(193 192 208 / var(--tw-text-opacity, 1));
}
.storybook-videos-list-item-stat.time-watched {
  min-width: 87px;
}
.storybook-videos-thumbnail {
  position: relative;
  width: 100%;
  overflow: hidden;
  padding-bottom: 56.25%;
}
.storybook-videos-description {
  font-size: 0.875rem;
  font-weight: 500;
  --text-opacity: 1;
  color: #10083e;
  color: rgba(16, 8, 62, var(--text-opacity));
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  margin-bottom: 0;
}
.storybook-videos-thumbnail img {
  position: absolute;
  height: 100%;
  width: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}
.storybook-videos-thumbnail-star {
  background: #d0d0d0;
  position: absolute;
  z-index: 10;
  padding: 0.25rem;
  top: 0px;
  left: 0px;
  border-radius: 0px;
  border-top-left-radius: 0.25rem;
}
.storybook-videos-thumbnail-star svg {
  margin: 0;
}
.storybook-videos-card-title {
  overflow: hidden;
  font-weight: 700;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.storybook-videos-card.white > * {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity, 1));
}
.storybook-videos-card.black > * {
  --tw-text-opacity: 1;
  color: rgb(0 0 0 / var(--tw-text-opacity, 1));
}
.storybook-videos-card-live-wrapper {
  position: relative;
  overflow: hidden;
  border-radius: 0.5rem;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  padding-bottom: 56.25%;
}
.storybook-videos-card img {
  width: 100%;
}
.storybook-videos-cards {
  gap: 0.5rem;
}
@media (min-width: 640px) {
  .storybook-videos-cards {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: 1rem;
  }
}
@media (min-width: 1024px) {
  .storybook-videos-cards {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }
}
.storybook-videos-card-time {
  position: absolute;
  right: 1rem;
  bottom: 1rem;
  border-radius: 0.5rem;
  background-color: rgb(0 0 0 / var(--tw-bg-opacity, 1));
  --tw-bg-opacity: 0.75;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  text-align: center;
  font-size: 0.75rem;
  line-height: 1rem;
  text-transform: uppercase;
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity, 1));
  line-height: 20px;
  height: 20px;
}
.storybook-videos-form-categories {
  margin-top: 1.5rem;
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  border-radius: 0.5rem;
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity, 1));
}
@media (min-width: 768px) {
  .storybook-videos-form-categories {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
}
@media (min-width: 1024px) {
  .storybook-videos-form-categories {
    margin-top: 3rem;
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }
}
.storybook-videos-form-category-item {
  padding: 1.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  --tw-text-opacity: 1;
  color: rgb(16 8 62 / var(--tw-text-opacity, 1));
  border-radius: 0.5rem;
  border-width: 1px;
  border-color: transparent;
}
.storybook-videos-form-category-item:hover {
  --tw-border-opacity: 1;
  border-color: rgb(226 232 240 / var(--tw-border-opacity, 1));
  --tw-text-opacity: 1;
  color: rgb(16 8 62 / var(--tw-text-opacity, 1));
  --tw-shadow: 0 20px 25px -5px rgb(0 0 0 / 0.1), 0 8px 10px -6px rgb(0 0 0 / 0.1);
  --tw-shadow-colored: 0 20px 25px -5px var(--tw-shadow-color), 0 8px 10px -6px var(--tw-shadow-color);
  box-shadow:
    var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000),
    var(--tw-shadow);
}
.storybook-videos-form-category-item svg {
  --tw-text-opacity: 1;
  color: rgb(226 232 240 / var(--tw-text-opacity, 1));
}
.storybook-videos-card-live {
  position: relative;
  display: block;
}
.storybook-videos-card-live .storybook-videos-card-live-wrapper::before {
  content: "";
  background:
    transparent linear-gradient(
      180deg,
      #00000000 0,
      #000000 100%) 0 0 no-repeat padding-box;
  position: absolute;
  inset: 0px;
  top: auto;
  height: 50%;
}
.storybook-videos-card-live .storybook-videos-card-live-wrapper > * {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity, 1));
}
.storybook-videos-card-live .storybook-videos-card-live-text-wrapper {
  position: absolute;
  bottom: 2rem;
  left: 2rem;
}
.rtmp-hls-wrapper {
  max-width: 630px;
}
.storybook-videos-card-live .storybook-videos-card-live-wrapper .storybook-videos-card-live-type {
  position: static;
}
.storybook-videos-form-schedule-list {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.storybook-videos-card-live-title {
  margin-top: 0.5rem;
  font-weight: 700;
}
.storybook-videos-card-live-type {
  margin-right: 0.25rem;
  display: inline-flex;
  align-items: center;
  border-radius: 0.125rem;
  --tw-bg-opacity: 1;
  background-color: rgb(239 68 68 / var(--tw-bg-opacity, 1));
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  font-size: 0.75rem;
  line-height: 1rem;
  text-transform: uppercase;
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity, 1));
}
.storybook-videos-card-live-sport {
  position: relative;
  top: 0.5rem;
  margin-left: 0.5rem;
  display: inline-flex;
}
.storybook-videos-card-live-sport svg {
  fill: white;
}
.storybook-videos-card-live-type span {
  margin-right: 0.5rem;
  height: 0.5rem;
  width: 0.5rem;
  border-radius: 9999px;
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity, 1));
}
.storybook-videos-search-list {
  max-width: 540px;
  margin: 0 auto;
}
.storybook-videos-search-list .storybook-videos-card {
  display: flex;
  gap: 1rem;
}
.storybook-videos-search-list .storybook-videos-card-thumbnail {
  max-width: 185px;
  height: 105px;
  padding-bottom: 0;
}

/* src/components/Vouchers/Vouchers.css */
.storybook-vouchers-card-circle-mask {
  position: absolute;
  height: 2.5rem;
  width: 2.5rem;
  --tw-bg-opacity: 1;
  background-color: rgb(247 250 252 / var(--tw-bg-opacity, 1));
  border-radius: 9999px;
}
.storybook-vouchers-card-circle-mask-tl {
  top: -1.25rem;
  left: -1.25rem;
}
.storybook-vouchers-card-circle-mask-tr {
  top: -1.25rem;
  right: -1.25rem;
}
.storybook-vouchers-card-circle-mask-br {
  bottom: -1.25rem;
  right: -1.25rem;
}
.storybook-vouchers-card-circle-mask-bl {
  bottom: -1.25rem;
  left: -1.25rem;
}
